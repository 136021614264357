import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthEvent, EventType } from './auth-event';
import { ExpAuthConfig } from './exp-auth-config';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { routerNgProbeToken } from '@angular/router/src/router_module';

const helper = new JwtHelperService();

@Injectable()
export class ExpAuthService extends BehaviorSubject<AuthEvent> {
    private logoutBaseURl = `https://${environment.ssoDomain}/logout?response_type=code`;

    private authGrantType = 'authorization_code';
    private refreshGrantType = 'refresh_token';

    private identity;
    decodedToken = {};
    decodedTokenChange: Subject<any> = new Subject<any>();


    constructor (private http: HttpClient, private config: ExpAuthConfig) {
      super(null);
      this.decodedTokenChange.subscribe((value) => {
        this.decodedToken = value;
      });
    }

    setDecodedToken() {
      localStorage.setItem('token', JSON.stringify(helper.decodeToken((this.identity.id_token))));
      this.decodedTokenChange.next(helper.decodeToken(this.identity.id_token));
    }

    getConfig(): ExpAuthConfig {
        return this.config;
    }
    signout() {
      window.location.assign(`${this.logoutBaseURl}&client_id=${environment.clientId}&redirect_uri=${environment.redirectUri}`);
    }

    authorize(code: string) {
        let headers: HttpHeaders = new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('Authorization', this.config.clientAuthoriztion);
        this.http.post(`https://${this.config.ssoDomain}/oauth2/token`, this.generateAuthorizePostBody(code), {headers})    .subscribe(
            (data) => {
              this.identity = data;
              this.setDecodedToken();
              this.next(new AuthEvent(EventType.LOGIN, 'Login Successful'));
            },
            (error) => {
              console.log('Authorization Failed!');
              this.next(new AuthEvent(EventType.ERROR, 'Authorization failed'));
            }
        );
        return this;
    }

    refreshAuthorization() {
        let headers: HttpHeaders = new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('Authorization', this.config.clientAuthoriztion);
        this.http.post(`https://${this.config.ssoDomain}/oauth2/token`, this.generateRefreshPostBody(), {headers}).subscribe(
          (data) => {
            const refresh_token = this.identity.refresh_token;
            this.identity = data;
            this.setDecodedToken();
            this.identity.refresh_token = refresh_token;
            this.next(new AuthEvent(EventType.REFRESH, 'Auth Refresh Successful'));
          },
          (error) => {
            console.log('Authorization refresh Failed!');
            this.next(new AuthEvent(EventType.ERROR, 'Authorization refresh failed'));
          }
        );
        return this;
    }

    private generateRefreshPostBody() {
      const body = new HttpParams()
        // .set('redirect_uri', this.config.redirectUrl)
        .set('grant_type', this.refreshGrantType)
        .set('refresh_token', this.identity.refresh_token)
        .set('client_id', this.config.clientId);
      return body;
    }

    private generateAuthorizePostBody(code: string) {
      const body = new HttpParams()
        .set('code', code)
        .set('redirect_uri', this.config.redirectUrl)
        .set('grant_type', this.authGrantType)
        .set('client_id', this.config.clientId);
      return body;
    }

    getAccessToken(): string {
      if (this.identity && this.identity.access_token) {
        return this.identity.access_token ? this.identity.access_token : '';
      }
      return '';
    }

    isAuthenticated(): boolean {
      if (this.identity && this.identity.access_token && this.identity.id_token && this.identity.refresh_token) {
        return true;
      }
      return false;
    }

    evalGroupStatus(group) {
      const testToken = this.decodedToken;
      if (testToken != null) {
        if (this.decodedToken && testToken['cognito:groups'] &&
        (testToken['cognito:groups'].indexOf('SerialAdmin') !== -1 || testToken['cognito:groups'].indexOf(group) !== -1)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

}
