import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})


export class TranslationService {
  private languageSource = new BehaviorSubject<string>('eng');
  currentLanguage = this.languageSource.asObservable();

  public languages = ['eng', 'esp'];
  public language;


  public dictionary: {[key: string]: TranslationSet} = {
    'eng' : {
        languange: 'eng',
        values: {
        }
    },
    'esp' : {
        languange: 'esp',
        values: {
            'Orders': 'órdenes',
            'Find Order or Serial Number': 'Buscar orden o número de serie',
            'FIND': 'BUSCAR',
            'ASSEMBLY': 'MONTAJE',
            'PREBUILD': 'PREPARACIÓN',
            'SEARCH': 'BUSCAR',
            'CUSTOMER SERVICE': 'SERVICIO AL CLIENTE',
            'RECON': 'REACONDICIONAMIENTO',
            'Saved Helmets': 'Cascos salvados',
            'Helmet': 'Casco',
            'of': 'de',
            'All': 'Todo',
            'Scanned': 'Escaneado',
            'Scan NOCSAE Barcode..': 'Escanear código de barras NOCSAE..',
            'CONFIGURATION DETAILS': 'DETALLES DE CONFIGURACIÓN',
            'helmets have been scanned': 'cascos han sido escaneados',
            'Quality Control': 'Control de calidad',
            'APPROVE': 'APROBAR',
            'Wrong': 'Incorrecto',
            'Missing' : 'Desaparecido',
            'Damaged' : 'Dañado',
            'Other' : 'Otro',
            'Status': 'Estado',
            'Assembly Date': 'Fecha de montaje',
            'BARCODES': 'Códigos de barras',
            'RECON BARCODES': 'Códigos de barras',
            'RECON ASSEMBLY': 'REACONDICIONAMIENTO MONTAJE'
        }
    }
  };

  constructor() {
    if (sessionStorage.getItem('language')) {
      this.languageSource.next(sessionStorage.getItem('language'));
    }
    this.currentLanguage.subscribe(lang => this.language = lang);
  }

  changeLanguage(language) {
    this.languageSource.next(language);
    sessionStorage.setItem( 'language', language);
  }

  translate(key: string): string {
    if (this.dictionary[this.language] != null) {
      if (this.language === 'eng') {
        return key;
      } else {
        return this.dictionary[this.language].values[key];
      }
    }
  }

}
export class TranslationSet {
  public languange: string;
  public values: {[key: string]: string} = {};
}
