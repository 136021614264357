import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Order } from 'src/app/order';
import { ProductService } from 'src/app/services/product.service';
import { MessageService } from 'src/app/services/message.service';
import { OrderService } from 'src/app/services/order.service';
import { UIService } from 'src/app/services/ui.service';
import {Howl, Howler} from 'howler';

@Component({
  selector: 'app-pick-pack-input',
  templateUrl: './pick-pack-input.component.html',
  styleUrls: ['./pick-pack-input.component.css']
})
export class PickPackInputComponent implements OnInit, AfterViewInit, OnChanges {
  public form: FormGroup;
  @Input() order: Order;
  boxSize;
  orderArr;
  disabled = false;
  deDupedArr = [];

  @ViewChild('nocsaeInput') nameField: ElementRef;

  sound = new Howl({
    src: ['../../../assets/sounds/coin.wav']
  });
  failSound = new Howl({
    src: ['../../../assets/sounds/fail.wav']
  });


  ngAfterViewInit() {
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.nameField.nativeElement.focus();
    },0);
  }
  ngOnChanges() {
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.nameField.nativeElement.focus();
    },0);
  }

  constructor(
    private fb: FormBuilder,
    private productService: ProductService,
    private messageService: MessageService,
    private orderService: OrderService,
    private uiService: UIService) { }

  ngOnInit() {
    this.form = this.fb.group({
      productValue: new FormControl({ value: '', disabled: this.disabled })
    });
    this.uiService.boxSize.subscribe(boxSize => {this.boxSize = boxSize});
    this.orderArr = this.order.products.map(product => product.expId);
    if ( this.orderArr.length >= this.order.productQuantity ) {
      console.log("disable");
      this.toggleInput(false, 'All products have been scanned');
    }
  }

  onNOCSAEInputEntry(term: string) {
    let tempInput = term.replace(/[^a-z0-9+]/gi, '').split('NC').slice(1).map(product =>  product = 'NC' + product);
    this.deDupedArr = this.deDupeAndFormat(tempInput);

    if (this.deDupedArr.length == this.boxSize && this.deDupedArr[(this.boxSize - 1)].length == 12) {
      this.addPickAndPack(this.deDupeAndFormat(tempInput));
      this.toggleInput(false, 'Adding product...');
    }
  }

  addPickAndPack(dedupedArr) {
    let postArr = [];
    let errorMessage = '';
    for (let product of dedupedArr) {
      postArr.push(this.configureOperationBody('PICKPACK', product, this.order.expId));
    }
    let deleteOperations = false;
    let productIds = {
      expIds: [],
      productexpIds: []
    };

    this.productService.addpickAndPackProducts(postArr)
    .subscribe(responses => {
      for (let response of responses) {
        if (response.success) {
          productIds.expIds.push(response.body.expId);
          productIds.productexpIds.push(response.body.productExpId);
        } else if (!response.success) {
          errorMessage = response.error;
          deleteOperations = true;
          break;
        }
      }

      this.toggleInput(true);
      this.deDupedArr = [];
      if (deleteOperations) {
        this.playFailSound();
        this.messageService.add({type: 'alert', message: errorMessage + ' \n All helmets in this box will be removed'});
        this.deleteOperationsOnFailure(productIds.expIds);
      } else {
        this.sound.play();
        for (let id of productIds.productexpIds) {
          this.messageService.add({type: 'success', message: `${id} successfully added`});
        }
        this.orderService.selectOrder(this.order.expId);
      }
    });
  }

  // Need this for async
  async playFailSound() {
    return this.failSound.play();
  }

  deleteOperationsOnFailure(idArr) {
    this.productService.deleteOperations(idArr).subscribe( responses => {
      for (let response of responses) {
        if (response.success) {
          // this.messageService.add({type: 'error', message: `${response.body.productExpId} was removed from the order`});
        } else {
          this.messageService.add({type: 'error', message: response.error});
        }
      }
    });
  }
  configureOperationBody(operationType, term, productConfig) {
    console.log(this.productService.helmetSizeforPP);
    return  {
      'object': {
        'type': operationType,
        'productExpId': term,
        'productConfigExpId': productConfig,
        'helmetModel': this.productService.helmetSizeforPP
      },
      'options': {
        'include': []
      }
    };
  }

  toggleInput(enabledBoolean: boolean, value: string = '') {
    enabledBoolean ? this.form.controls['productValue'].enable() : this.form.controls['productValue'].disable();
    this.form.controls['productValue'].setValue(value);

    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.nameField.nativeElement.focus();
    },0);
  }

  deDupeAndFormat(inputArray) {
    // let orderArr = this.order.products.map(product => product.expId);
    // console.log(`Order array --> ${this.orderArr}`);
    // console.log(`checking for dedupe --> ${inputArray}`);
    let deDuped = [];
    inputArray.map(input => {
      if (!this.orderArr.includes(input)) {
        deDuped.push(input);
      }
    });
    return deDuped = Array.from(new Set(deDuped));
    // return deDuped;
  }


}
