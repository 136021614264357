import { Component, OnInit } from '@angular/core';
import { ExpAuthService } from 'src/app/exp-auth/exp-auth.module';
import { TranslationService } from 'src/app/services/translation.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  ASSEMBLY = 'ASSEMBLY';
  PREBUILD = 'PREBUILD';
  CS = 'CUSTOMER SERVICE';
  RECON = 'RECON';
  BARCODES = 'BARCODES';
  qcStatus = false;
  binding = false;

  langfromService: string;
  language = true;

  decodedToken = {'name': 'username'};

  constructor(private authService: ExpAuthService, private translateService: TranslationService) {
    this.authService.decodedTokenChange.subscribe((value) => {
      this.decodedToken = value;
      this.evalQCStatus();
    });
    this.updateToggleValue();
  }

  ngOnInit() {
    this.translateService.currentLanguage.subscribe(lang => this.langfromService = lang);
    this.updateToggleValue();
    // this.evalQCStatus();
  }
  newLanguage() {
    this.updateToggleValue();
    if(this.langfromService === "eng") {
      this.translateService.changeLanguage("esp");
    } else {
      this.translateService.changeLanguage("eng");
    }
  }
  updateToggleValue() {
    if (this.langfromService === 'eng') {
      this.language = false;
    } else if (this.langfromService === 'esp') {
      this.language = true;
    }

  }
  signOut() {
    // console.log('Attempting to signout');
    this.authService.signout();
  }
  evalQCStatus() {
    this.qcStatus =  this.authService.evalGroupStatus('QC');
  }
}
