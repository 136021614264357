import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MessageService } from 'src/app/services/message.service';
import { OrderService } from 'src/app/services/order.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-asm-main-input',
  templateUrl: './asm-main-input.component.html',
  styleUrls: ['./asm-main-input.component.css']
})


export class ASMMainInputComponent implements OnInit, AfterViewInit {
  @Input() configuration;
  mainInput;
  loading = true;

  disabled = false;


  constructor(
    private messageService: MessageService,
    private orderService: OrderService,
    private router: Router) { }

  @ViewChild('mainInput') nameField: ElementRef;
  // editName(): void {
  //   this.nameField.nativeElement.focus();
  // }
  ngOnInit() {
    this.loading = false;
    // this.NCinput.focus();

  }

  ngAfterViewInit() {
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.nameField.nativeElement.focus();
    }, 0);
  }

  onASMInputEntry(term: string) {
    console.log('Checking barcode entry');
    // if (term.startsWith(this.configuration.orderType) && term.split('-').length === 6 && term.split('-')[5].length === 5) {
    if ((this.configuration.orderType.includes(term.slice(0, 3)) || this.configuration.orderType.includes(term.slice(0, 2)))
        && term.split('-').length === 6
        && term.split('-')[5].length === 5) {
      console.log('Calling add product config');
      this.addOrder(term);
      this.loading = true;
    } else if (term.startsWith('NC') && term.length >= 12 || term.startsWith('S') && term.length >= 11) {
      this.router.navigateByUrl(`/serial/${term}`);
    } else if (!(this.configuration.orderType.includes(term.slice(0, 3)) || this.configuration.orderType.includes(term.slice(0, 2)))
        && term.split('-').length === 6) {
      console.log(term);
      this.messageService.add({type: 'error', message: `You can only enter ${this.configuration.orderType} orders on this screen`});
    }
  }
  addOrder(term): void {
      this.orderService.addOrderWithObservable(this.configureOrder(term))
      .subscribe(response => {
        if (response.success) {
          this.router.navigateByUrl(`${this.configuration.rootUrl}${response.body.expId}`);
        } else {
          this.messageService.add({type: 'error', message: 'Could not save order to Database'});
        }
      });
  }
  configureOrder(term) {
    const orderArr = term.split('-');
    return {
      'object': {
        'expId': orderArr[0] + '-' + orderArr[1],
        'configKey': orderArr[1],
        'productQuantity': orderArr[4],
        'productModel': orderArr[2],
        'productColor': orderArr[3]
      },
      'options': {
        'include': ['Product', 'WorkOrder']
      }
    }
  }
}
