import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatUsername'
})
export class FormatUsernamePipe implements PipeTransform {

  transform(value: string, args?: any): string {
    return value.split(/(?=[A-Z])/).join(' ');
  }

}
