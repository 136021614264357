import { Component, OnInit, Input, OnChanges, ComponentFactoryResolver } from '@angular/core';
import { MessageService } from 'src/app/services/message.service';
import { ExpAuthService } from 'src/app/exp-auth/exp-auth.module';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-cs-helmet-details',
  templateUrl: './cs-helmet-details.component.html',
  styleUrls: ['./cs-helmet-details.component.css']
})
export class CsHelmetDetailsComponent implements OnInit, OnChanges {
  @Input() helmet;
  qcOrder;
  currentQCOperation;
  expirationDate: Date;
  retireDate: Date;
  qcGroup = false;
  constructor(private messageService: MessageService, private authService: ExpAuthService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.calcDates(this.helmet.assemblyDate);
    this.evalQCStatus();
    this.currentQCOperation = this.calcOperations(this.helmet);
  }

  ngOnChanges() {
    if (this.helmet) {
      this.calcDates(this.helmet.assemblyDate);
      this.currentQCOperation = this.calcOperations(this.helmet);
    }
  }

  calcOperations(helmet) {
    const tempCurrentQCOperation = helmet.operations.filter((operation) => operation.type === 'QC')[0];
    this.qcOrder = this.calcQCOrder(this.helmet.productConfigs, tempCurrentQCOperation);
    return tempCurrentQCOperation;
  }

  calcQCOrder(productConfigs, currentQCOperation) {
    return productConfigs.filter(config => config.orderNumber == currentQCOperation.workOrderExpId)[0];
  }

  calcDates(date) {
    let dt = new Date(date);

    function makenewDate(input, years) {
      return new Date(input.setFullYear(input.getFullYear() + n));
    }
    // number of years to add for warranty
    const n = 5;
    // number of years for retirement
    const m = 10;
    this.expirationDate = makenewDate(dt, n);
    this.retireDate = makenewDate(dt, m);
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.messageService.add({type: 'success', message: 'Order copied to clipboard'});
  }
  evalQCStatus() {
    this.qcGroup = this.authService.evalGroupStatus('QC');
  }
}
