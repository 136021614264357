export const environment = {
  production: true,
  expSerialBaseUrl: 'https://snes-eus-as-prod.azurewebsites.net/api/',
  slackSerialUrl: 'https://hooks.slack.com/services/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',
  expSerailToken: 'token=6c0945889ede631eae7e276695fc71349903e5228702f687602110c1af62prod',
  clientId: '6ck5t20rhhvr84afet2qtjahuu',
  clientAuthoriztion: 'Basic NmNrNXQyMHJoaHZyODRhZmV0MnF0amFodXU6ZzM2MGxpOG1yNW5ramp1MDluYjNxaDdzanY1M3BzbzAxa2E5OGZmNTg4NzBiMjJ0MzY5',
  redirectUrl: 'https://snes-eus-as-prod.azurewebsites.net/sso_success',
  redirectUri: 'https://snes-eus-as-prod.azurewebsites.net/',
  ssoDomain: 'auth.xenith.io'
};
