import { Component, OnInit } from '@angular/core';
import { QcService } from 'src/app/services/qc.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-qc-list',
  templateUrl: './qc-list.component.html',
  styleUrls: ['./qc-list.component.css']
})
export class QcListComponent implements OnInit {

  constructor(public qcService: QcService, public orderService: OrderService) { }

  ngOnInit() {

  }
  getData() {
    this.orderService.getOrderByOrderForQC(this.qcService.workOrder.split('-')[0]).subscribe(response => {
      if (response.success) {
        console.log(response.body);
      }
    });
  }

  close() {
    this.qcService.clear();
  }
}
