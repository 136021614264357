import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ProductService } from 'src/app/services/product.service';
import { MessageService } from 'src/app/services/message.service';
import {Howl, Howler} from 'howler';

@Component({
  selector: 'app-serial-add-nocsae',
  templateUrl: './serial-add-nocsae.component.html',
  styleUrls: ['./serial-add-nocsae.component.css']
})
export class SerialAddNocsaeComponent implements OnInit {
  clicked = false;
  public nocsaeForm: FormGroup;

  sound = new Howl({
    src: ['../../../assets/sounds/addnocsae.wav']
  });

  constructor(
    private productService: ProductService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.nocsaeForm = new FormGroup({
      originalSerial: new FormControl(''),
      nocsaeSerial: new FormControl('')
    });
  }

  onSubmit() {
    // console.log(this.nocsaeForm.value);
    if (this.validateValues(this.nocsaeForm.value)) {
      this.toggleButton();
      this.productService.addnocsaeProductWithObservable(this.formatBarcodeBody(this.nocsaeForm.value))
      .subscribe(response => {
        if (response.success) {
          // this.formEvent.emit({serial: this. nocsaeForm.value.newSerial, mfg: this. nocsaeForm.value.date});
          this.messageService.add({type: 'success', message: `${this.nocsaeForm.value.nocsaeSerial} added`});
          this.clearInput('all');
          this.sound.play();
        } else {
          this.messageService.add({type: 'error', message: 'Could not replace serial Number'});
          this.messageService.add({type: 'error', message: response.error});
        }
        this.toggleButton();
      });
    }
  }
  toggleButton() {
    this.clicked ? this.clicked = false : this.clicked = true;
  }

    validateValues(formValues) {
      if (formValues.nocsaeSerial.startsWith('NC')) {
        return true;
      } else {
        this.messageService.add({type: 'error', message: 'Your new barcode is not formatted properly'});
        this.clearInput('newSerial');
        return false;
      }
    }

    formatBarcodeBody(formValues) {
      return {
        'object': {
          'expId': formValues.originalSerial,
          'nocsae': formValues.nocsaeSerial
        },
       'options': {
         'include': []
        }
      }
    }

    clearInput(field) {
      if (field === 'originalSerial' || field === 'all') {
      this.nocsaeForm.controls['originalSerial'].setValue('');
      }
      if (field === 'newSerial' || field === 'all') {
        this.nocsaeForm.controls['nocsaeSerial'].setValue('');
      }
    }
}
