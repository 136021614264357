import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material';

import * as _moment from 'moment';
import { default as _rollupMoment, Moment} from 'moment';

const moment =  _rollupMoment || _moment;

@Component({
  selector: 'app-serial-reprint',
  templateUrl: './serial-reprint.component.html',
  styleUrls: ['./serial-reprint.component.css']
})

export class SerialReprintComponent implements OnInit {
  @Output() formEvent = new EventEmitter<any>();

  reprintForm = new FormGroup({
    serialNumber: new FormControl(''),
    date: new FormControl(moment())
  });

  startDate = new FormControl(new Date(2019, 0, 1));

  constructor() { }

  ngOnInit() {
  }

  onSubmit() {
    // console.log(this.reprintForm.value);
    this.formEvent.emit({serial: this.reprintForm.value.serialNumber, mfg: this.reprintForm.value.date});

    // this.reprintForm.patchValue({date: '', serialNumber: ''});
  }


  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.reprintForm.value.date;
    ctrlValue.year(normalizedYear.year());
    this.reprintForm.patchValue({date: ctrlValue});
  }

  chosenMonthHandler(normlizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.reprintForm.value.date;
    ctrlValue.month(normlizedMonth.month());
    this.reprintForm.patchValue({date: ctrlValue});
    datepicker.close();
  }
}
