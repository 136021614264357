import { Component, OnInit } from '@angular/core';
import { BarcodeService } from 'src/app/services/barcode.service';
import { ExpAuthService } from 'src/app/exp-auth/exp-auth.module';

@Component({
  selector: 'app-recon-barcodes',
  templateUrl: './recon-barcodes.component.html',
  styleUrls: ['./recon-barcodes.component.css']
})
export class ReconBarcodesComponent implements OnInit {
  submitted = false;
  group;
  constructor(
    private barcodeService: BarcodeService,
    private authService: ExpAuthService) { }

  ngOnInit() {

    this.evalGroupStatus();
  }

  receiveFormValues($event) {
    const serialValues = $event;
    // console.log('serial values:' + JSON.stringify(serialValues));
    this.printBarcodes(serialValues);
  }
  evalGroupStatus() {
    // change to recon group when you create it
    this.group = this.authService.evalGroupStatus('QC');
  }

  printBarcodes(value) {
    this.barcodeService.changeBarcode({serial: value.serial, mfg: value.mfg});
    this.submitted = true;
    setTimeout(() => { window.print();  this.submitted = false; } , 300);
  }
}
