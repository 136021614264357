import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Order } from '../../order';
import { OrderService } from '../../services/order.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-last-orders',
  templateUrl: './last-orders.component.html',
  styleUrls: ['./last-orders.component.css']
})



export class LastOrdersComponent implements OnInit {
  FIND = 'FIND';
  title = 'Orders';
  @Input() configuration;
  @Input() rootURL;
  filter;
  // orderListSub: Subscription;
  orderList: Order[];
  // orders: Order[];
  currentOrderId: string;
  // newOrderSub: Subscription;

  constructor(private orderService: OrderService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    const storedList = localStorage.getItem('orderList');
    // if (storedList) {
    //   this.orderList = JSON.parse(storedList);
    // }
    this.filter = this.configuration.filter;
    // this.rootURL = this.configuration.rootURL;

    this.currentOrderId = this.route.snapshot.params.id;
    // this.orderListSub = this.orderService.getOrders().subscribe(orders => {
    //   this.orderList = orders;
    //   // if (this.currentOrderId) {
    //   //   this.orderService.selectOrder(this.currentOrderId);
    //   // }
    // });
    this.orderService.getLastOrders(this.filter).subscribe(response => {
      if (response && response.success) {
        this.orderList = response.body;
        localStorage.setItem('orderList', JSON.stringify(response.body));
      }
    });
      // this.orders$.subscribe(orders => {console.log('order: ' + JSON.stringify(order)); this.currentOrder = order});

    // this.newOrderSub = this.orderService.newOrderChange.subscribe(order => {
    //   if (order) {
    //     this.currentOrderId = order.expId;
    //     this.orderService.getOrders();
    //     // setTimeout(() => {
    //     //   this.orderService.getOrders();
    //     // }, 2000);
    //   }
    // });

  }

  compare(a, b) {
    if (a.createdAt > b.createdAt) {return -1; }
    if (a.createdAt < b.createdAt) {return 1; }
    return 0;
  }

  sortByModifiedDate(a, b) {
    if (a.expModifiedDate < b.expModifiedDate) {
      return 1;
    }
    if (a.expModifiedDate > b.expModifiedDate) {
      return -1;
    }
    return 0;
  }


  // ngOnDestroy() {
  //   // this.newOrderSub.unsubscribe();
  //   // this.orderListSub.unsubscribe();
  // }

}
