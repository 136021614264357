import { NgModule, OnDestroy } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SerialDetailsComponent } from './serial-details/serial-details.component';
import { CustomerServiceViewComponent } from './cs/customer-service-view/customer-service-view.component';
import { AssemblyViewComponent } from './assembly/assembly-view/assembly-view.component';

// Auth imports
import { LoginWrapperComponent } from './login-wrapper/login-wrapper.component';
import { ExpAuthGuard } from './exp-auth/exp-auth-guard';

import { HomepageComponent } from './homepage/homepage.component';
import { ReconAssemblyViewComponent } from './recon/recon-assembly-view/recon-assembly-view.component';
import { ReconBarcodesComponent } from './recon/recon-barcodes/recon-barcodes.component';
import { PbAssemblyViewComponent } from './pb/pb-assembly-view/pb-assembly-view.component';
import { PickPackAssemblyComponent } from './pb/pick-pack-assembly/pick-pack-assembly.component';



const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginWrapperComponent
  },
  {
    path: 'sso_success',
    pathMatch: 'full',
    component: LoginWrapperComponent
  },
  { path: 'home', component: HomepageComponent, canActivate: [ ExpAuthGuard ]},
  { path: 'new-order', component: AssemblyViewComponent, canActivate: [ ExpAuthGuard ] },
  { path: 'new-order/:id', component: AssemblyViewComponent, canActivate: [ ExpAuthGuard ]},
  { path: 'recon/assembly', component: ReconAssemblyViewComponent, canActivate: [ ExpAuthGuard ] },
  { path: 'recon/barcodes', component: ReconBarcodesComponent, canActivate: [ ExpAuthGuard ] },
  { path: 'recon/assembly/:id', component: ReconAssemblyViewComponent, canActivate: [ ExpAuthGuard ] },
  { path: 'serial/:id', component: SerialDetailsComponent, canActivate: [ ExpAuthGuard ] },
  { path: 'pb/assembly', component: PbAssemblyViewComponent, canActivate: [ ExpAuthGuard ]},
  { path: 'pb/assembly/:id', component: PbAssemblyViewComponent, canActivate: [ ExpAuthGuard ]},
  { path: 'pick-pack/assembly', component: PickPackAssemblyComponent, canActivate: [ ExpAuthGuard ]},
  { path: 'pick-pack/assembly/:id', component: PickPackAssemblyComponent, canActivate: [ ExpAuthGuard ]},
  { path: 'cs', component: CustomerServiceViewComponent, canActivate: [ ExpAuthGuard ] } ,
  { path: 'cs/helmet/:id', component: CustomerServiceViewComponent, canActivate: [ ExpAuthGuard ]},
  { path: 'cs/order/:id', component: CustomerServiceViewComponent, canActivate: [ ExpAuthGuard ]},
  { path: '**', redirectTo: '/home' }
];


@NgModule({
  // declarations: [ LoginWrapperComponent ],
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule implements OnDestroy {

  constructor() {

    // orderService.orderChange.subscribe(order => {
    //   order && order.expId ? this.router.navigateByUrl('/order/' + order.expId) : console.log('Order ID undefined');
    // });
  }
  ngOnDestroy() {
    // this.orderService.orderChange.unsubscribe();
  }


}
