export class QCCodes {
  public static WRONG = [
    'Snap Buckles',
    'Facemask Color',
    'Facemask Style',
    'Label(s)',
    'Shell Color',
    'Paint Color',
    'Helmet Paint Should Be Injected',
    'Front Bumper Color',
    'Bumper Color',
    'Jaw Pad Size',
    'Jaw Plate Size',
    'Comfort Pad Size or Type',
    'Comfort Pad Installed Wrong',
    'Facemask Clip Color',
    'Chin Cup Color',
    'Chin Cup Style',
    'Bonnet Color',
    'Bonnet Style',
    'Bonnet Size',
    'Chin Cup Liner Color',
    'Other'
  ];

  public static MISSING = [
    'Manual',
    'Label(s)',
    'Facemask',
    'Upgrade Pad',
    'Jaw Pad',
    'Hardware (e.g. Screw)',
    'Shock Support',
    'Serial Number',
    'Fit Band',
    'Velcro Disk',
    'Long Screw in Facemask Clip',
    'Snap Buckle',
    'Other'
  ];

  public static DAMAGED = [
  'Shell',
  'Bonnet',
  'Jaw Pad',
  'Paint on Label',
  'Other'
  ];

  public static OTHER = [
    'Twisted Strap',
    'Loose Part in Helmet',
    'Twisted Label'
  ];

}
