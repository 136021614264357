import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import {MomentDateAdapter} from '@angular/material-moment-adapter';
import { FormControl, FormGroup } from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MatDatepicker } from '@angular/material';

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { BarcodeService } from 'src/app/services/barcode.service';
import { ProductService } from 'src/app/services/product.service';
import { MessageService } from 'src/app/services/message.service';

const moment =  _rollupMoment || _moment;

// export const MY_FORMATS = {
//   parse: {
//     dateInput: 'MM/YYYY',
//   },
//   display: {
//     dateInput: 'MM/YYYY',
//     monthYearLabel: 'MMM YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'MMMM YYYY',
//   },
// };


@Component({
  selector: 'app-serial-replacement',
  templateUrl: './serial-replacement.component.html',
  styleUrls: ['./serial-replacement.component.css'],
})
export class SerialReplacementComponent implements OnInit {
  @Output() formEvent = new EventEmitter<any>();

  // public mainForm: FormGroup;
  public replaceForm: FormGroup;

  // date = new FormControl(moment());
  startDate = new FormControl(new Date(2015, 0, 1));
  submitReplace = false;
  clicked = false;

  constructor(
    private barcodeService: BarcodeService,
    private productService: ProductService,
    private messageService: MessageService) { }

  ngOnInit() {
    // this.mainForm = this.fb.group({
    //   productValue: new FormControl({ value: '', disabled: this.disabled })
    // });

    this.replaceForm = new FormGroup({
      originalSerial: new FormControl(''),
      newSerial: new FormControl(''),
      date: new FormControl(moment())
    });

    this.barcodeService.currentBarcode.subscribe(barcode => this.replaceForm.value.originalSerial = barcode.serial);
  }

  clearInput(field) {
    if (field === 'originalSerial' || field === 'all') {
    this.replaceForm.controls['originalSerial'].setValue('');
    }
    if (field === 'newSerial' || field === 'all') {
      this.replaceForm.controls['newSerial'].setValue('');
    }
  }
  toggleButton() {
    this.clicked ? this.clicked = false : this.clicked = true;
  }
  onSubmit() {
    console.log(this.replaceForm.value);

    if (this.validateValues(this.replaceForm.value)) {
      this.toggleButton();
      this.productService.replaceBarcode(this.formatBarcodeBody(this.replaceForm.value))
      .subscribe(response => {
        if (response.success) {
          this.formEvent.emit({serial: this.replaceForm.value.newSerial, mfg: this.replaceForm.value.date});
          this.messageService.add({type: 'success', message: 'Serial number successfully updated'});
          this.clearInput('all');
        } else {
          this.messageService.add({type: 'error', message: 'Could not replace serial Number'});
          this.messageService.add({type: 'error', message: response.error});
        }
        this.toggleButton();
      });
    }
    //call backend with new serial and original serial

    // this.formEvent.emit({serial: this.replaceForm.value.newSerial, mfg: this.replaceForm.value.date});
  }
  validateValues(formValues) {
    if (formValues.newSerial.startsWith('NC')) {
      return true;
    } else {
      this.messageService.add({type: 'error', message: 'Your new barcode is not formatted properly'});
      this.clearInput('newSerial');
      return false;
    }
  }
  formatBarcodeBody(formValues) {
    return {
      'object': {
        'oldProductExpId': formValues.originalSerial,
        'newProductExpId': formValues.newSerial,
        'warrantyDate': formValues.date
      },
      'options': {
        'include': []
      }
    };
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.replaceForm.value.date;
    ctrlValue.year(normalizedYear.year());
    this.replaceForm.patchValue({date: ctrlValue});
  }

  chosenMonthHandler(normlizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.replaceForm.value.date;
    ctrlValue.month(normlizedMonth.month());
    this.replaceForm.patchValue({date: ctrlValue});
    datepicker.close();
  }

}
