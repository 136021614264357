import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Order } from 'src/app/order';
import { OrderService } from 'src/app/services/order.service';
import { ActivatedRoute, Router, RouterEvent, NavigationEnd } from '@angular/router';
import { BarcodeService } from 'src/app/services/barcode.service';
import { ProductService } from 'src/app/services/product.service';
import {Howl, Howler} from 'howler';
import { configuration } from 'src/app/services/assembly-view.service';


@Component({
  selector: 'app-asm-product-assembly',
  templateUrl: './asm-product-assembly.component.html',
  styleUrls: ['./asm-product-assembly.component.css']
})
export class ASMProductAssemblyComponent implements OnInit, OnDestroy {
  order: Order;
  orderSub;
  routerSub;
  submitted = false;
  productsComplete = false;
  @Input() configuration: configuration;

  // Send Batch to parent component(assembly);
  @Output() batchEvent = new EventEmitter<string>();

  sound = new Howl({
    src: ['../../../assets/sounds/success.wav']
  });

  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private barcodeService: BarcodeService,
    private productService: ProductService
  ) {
    this.orderSub = this.orderService.orderChange.subscribe(order => {
      this.order = order;


      if(order && order['workOrder'] && order['workOrder'].status) {
        // this.batchEvent.emit(order.batch);
        this.batchEvent.emit(order.workOrder.status);
      } else {
        this.batchEvent.emit(undefined);
      }


      if (order && order.products.length >= order.productQuantity) {
        this.productsComplete = true;
        this.sound.play();
      } else {
        this.productsComplete = false;
      }
    });
  }

  ngOnInit() {
    this.routerSub = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // this.getOrder();
        this.orderService.selectOrder(this.route.snapshot.paramMap.get('id'));
      }
    });
    this.orderService.selectOrder(this.route.snapshot.paramMap.get('id'));
  }

  printSerial(serialId: string): void {
    console.log('print serial called');
    this.barcodeService.changeBarcode({serial: serialId});
    this.submitted = true;
    setTimeout(() => { window.print(); this.submitted = false; }, 300);
    // this.barcodeValue = serialId;
    // setTimeout(() => { this.print(); } , 100 );
  }

  deleteSerial(delteObj): any {
    this.productService.deleteSerial(delteObj);
  }
  // getColor(configuration: configuration) {
  //   return
  // }

  ngOnDestroy() {
    this.orderSub.unsubscribe();
    this.routerSub.unsubscribe();
  }
}
