import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';

import {animate, state, style, transition, trigger} from '@angular/animations';

import { ConfigDecoderService } from 'src/app/services/config-decoder.service';
import { Observable } from 'rxjs';
import { ExpAuthService } from 'src/app/exp-auth/exp-auth.module';


@Component({
  selector: 'app-cs-order-details',
  templateUrl: './cs-order-details.component.html',
  styleUrls: ['./cs-order-details.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class CsOrderDetailsComponent implements OnInit, OnDestroy {
  orderReceived = false;
  // Table stuff
  displayedColumns: string[] = ['configKey', 'expId', 'assemblyDate', 'productQuantity', 'productModel', 'productColor'];
  order;
  eventsSubscription: any;
  @Input() events: Observable<void>;
  dataSource;
  qcGroup = false;
  expandedElement;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private decoder: ConfigDecoderService, private authService: ExpAuthService) { }

  ngOnInit() {
    this.eventsSubscription = this.events.subscribe((order) => {
      this.receiveOrder(order);
    });
    this.evalQCStatus();
  }
    // this.dataSource = new MatTableDataSource(this.configureOrder(this.order));
    // // console.log("The order is: " + JSON.stringify(this.order));
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;

  receiveOrder(order) {
    if (!order) {
      return console.log(`the order is null`);
    }
    this.order = order;
    this.dataSource = new MatTableDataSource(this.configureOrder(this.order));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.orderReceived = true;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  toggleExpand(config) {
    if (this.expandedElement === config) {
      this.expandedElement = null;
    } else {
      this.expandedElement = config;
    }
  }
  configureOrder(ord) {
    for (const config of ord.productConfigs) {
      config.productModel = this.decoder.getModelWithSize(config.productModel);
      config.productColor = this.decoder.getColor(config.productColor);
      //  populate products array on each config with correct products from seperate products array
      config['products'] = [];
      let count = 0;
      ord.products.map((product) => {
        if (product.operation.productConfigExpId == config.expId) {
          config.products.push({'expId' : product.expId, 'QC' : product.QC});
          if (product.QC === 'APPROVED') {
            count++;
          }
        }
      });
      config['passing'] = count;
    }
    // console.log(ord.productConfigs);
    return ord.productConfigs;
  }
  evalQCStatus() {
    this.qcGroup = this.authService.evalGroupStatus('QC');
  }
  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}
