import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, AfterViewInit, OnChanges } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/services/message.service';
import { OrderService } from 'src/app/services/order.service';
import { FormGroup, FormControl } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { UIService } from 'src/app/services/ui.service';
import { AssemblyViewService } from 'src/app/services/assembly-view.service';
import { BarcodeService } from 'src/app/services/barcode.service';

@Component({
  selector: 'app-asm-product-input',
  templateUrl: './asm-product-input.component.html',
  styleUrls: ['./asm-product-input.component.css']
})
export class ASMProductInputComponent implements OnInit, AfterViewInit, OnChanges {
  // productValue;
  public form: FormGroup;
  submitted = false;
  @Input() productConfig;
  @Input() configuration;
  @Output() OnrePrint = new EventEmitter();
  disabled = false;

  @ViewChild('nocsaeInput') nameField: ElementRef;


  ngAfterViewInit() {
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.nameField.nativeElement.focus();
    },0);
  }
  ngOnChanges() {
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.nameField.nativeElement.focus();
    },0);
  }
  constructor(
    private fb: FormBuilder,
    private productService: ProductService,
    private barcodeService: BarcodeService,
    private orderService: OrderService,
    private router: Router,
    private uiService: UIService,
    private view: AssemblyViewService,
    private messageService: MessageService) { }

  ngOnInit() {
    // console.log(JSON.stringify(this.configuration));
    this.form = this.fb.group({
      productValue: new FormControl({ value: '', disabled: this.disabled })
    });
  }
  onNOCSAEInputEntry(term: string) {
    if (this.configuration.filter !== 'Recon') {
      if (term.slice(0, 2) === 'NC' && term.length === 12) {
        this.addProduct(term);
        this.toggleInput(false, 'Adding product...');
      } else if (term.slice(0, 2) !== 'NC' && term.length >= 10) {
        this.messageService.add({type: 'warn', message: 'You can only enter NOCSAE numbers ex: NC000001234'});
        setTimeout(() => {
          this.toggleInput(true);
        }, 1000);
        this.toggleInput(false, 'Adding product...');
      }
    }
  }
  onSubmit(term) {
    if (term.length >= 3 && !this.submitted)  {
      this.submitted = true;
      this.addProduct(term);
      this.toggleInput(false, 'Adding product...');
    }
  }
  addProduct(term) {
    switch (this.configuration.filter) {
      case 'Assembly':
      this.addNormalProduct(term);
      break;
      case 'Recon':
      this.addRCProduct(term);
      break;
      case 'Prebuild':
      this.addPreBuild(term);
      break;
      // case 'PICKPACK':
      // this.addPickAndPack(term);
      // break;
      default:
      this.messageService.add({type: 'error', message: 'Error with Order Type'});
      break;
    }
  }

  addPreBuild(term) {
    this.productService.addPreBuildProductWithObservable(this.configureBody(term, this.productConfig))
    .subscribe(response => {
      this.handleResponse(response, term);
    });
  }
  // addPickAndPack(term) {
  //   this.productService.addpickAndPackProductWithObservable(this.configureOperationBody('PICKPACK', term, this.productConfig))
  //   .subscribe(response => {
  //     this.handleResponse(response);
  //   });
  // }
  addRCProduct(term) {
    this.productService.addReconProductWithObservable(this.configureBody(term, this.productConfig))
    .subscribe(response => {
      this.handleResponse(response);
    });
  }

  addNormalProduct(term) {
    this.productService.addProductWithObservable(this.configureBody(term, this.productConfig))
    .subscribe(response => {
      this.handleResponse(response, term);
    });
  }

  configureBody(term, productConfig) {
    return {
      'object': {
        'expId': term,
        'productConfigExpId': productConfig
      },
      'options': {
        'include': ['Operation']
      }
    };
  }

  configureOperationBody(operationType, term, productConfig) {
    return  {
      'object': {
        'type': operationType,
        'productExpId': term,
        'productConfigExpId': productConfig
      },
      'options': {
        'include': ['Operation']
      }
    };
  }

  handleResponse(response, print= null) {
    if (response.success) {
      this.orderService.selectOrder(this.productConfig);
      if (print) { this.print(print); console.log('calling print'); }
    } else {
      this.messageService.add({type: 'error', message: 'Could not save product to Database'});
      this.messageService.add({type: 'error', message: response.error});
    }
    this.toggleInput(true);
    this.submitted = false;
    this.uiService.savvingHelmet.next(false);
  }

  print(serialId) {
    this.OnrePrint.emit(serialId);
  }

  toggleInput(enabledBoolean: boolean, value: string = '') {
    enabledBoolean ? this.form.controls['productValue'].enable() : this.form.controls['productValue'].disable();
    this.form.controls['productValue'].setValue(value);

    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.nameField.nativeElement.focus();
    },0);
  }
}
