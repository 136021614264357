import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, RoutesRecognized } from '@angular/router';
import { AuthEvent, EventType, LoginComponent } from '../exp-auth/exp-auth.module';


@Component({
    selector: 'app-login-wrapper',
    templateUrl: './login-wrapper.component.html',
    styleUrls: ['./login-wrapper.component.css']
})
export class LoginWrapperComponent implements OnInit {

    constructor(private router: Router) { }

    ngOnInit() {
    }
    loginEvent(event: AuthEvent, returnUrl= ['home']) {
        // console.log('a login thing happened');
        // console.log(event);
        if (event.type == EventType.LOGIN) {
          this.router.navigate(returnUrl);
        }
    }
}
