import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cs-helmet-history',
  templateUrl: './cs-helmet-history.component.html',
  styleUrls: ['./cs-helmet-history.component.css']
})
export class CsHelmetHistoryComponent implements OnInit {
  @Input() helmet;
  // Partial list from Backend operationService.js file;
  operationTypes = ['RECALLED', 'FRAUDULENT', 'RETURNED', 'REPLACED', 'DEACTIVATED'];

  constructor() { }

  ngOnInit() {
  }
  isDeactivated(opType) {
    return this.operationTypes.includes(opType);
  }
}
