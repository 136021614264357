export class ColorConstants {
  public static HEX_COLORS = {
    '00': 'FloralWhite',
    '01': 'Black',
    '02': 'Grey',
    '03': 'FloralWhite',
    '04': 'Navy',
    '05': '#004FA0',
    '06': 'DarkRed',
    '07': 'Maroon',
    '08': 'GoldenRod',
    '09': 'DarkGreen',
    '10': 'Orange',
    '11': 'DarkRed',
    '12': 'Blue',
    '13': 'Purple',
    '14': 'DarkGreen',
    '18': 'Brown',
  };

  public static XENITH_SHELL_COLORS = {
    '00000': 'Clear',
    '01000': 'Black',
    '01001': 'Painted Black',
    '01002': 'Matte Black',
    '01100': 'Metallic Black',
    '02000': 'Grey',
    '02001': 'Painted Grey',
    '02005': 'Dallas Blue',
    '02009': 'Matte Grey',
    '02101': 'Metallic Silver',
    '02102': 'EB Metallic Silver',
    '02103': 'Oakland Silver',
    '03000': 'White',
    '03001': 'Painted White',
    '03100': 'Metallic White',
    '03101': 'EB Metallic White',
    '03106': 'Matte White',
    '04000': 'Navy',
    '04001': 'Painted Navy',
    '04002': 'Denver Blue',
    '04007': 'Matte Navy',
    '04100': 'Metallic Navy',
    '05000': 'Royal Blue',
    '05001': 'Painted Royal Blue',
    '05005': 'Kansas Blue',
    '05009': 'Matte Royal Blue',
    '05100': 'Metallic Royal Blue',
    '05105': 'Metallic Kansas Blue',
    '06000': 'Scarlet',
    '06001': 'Painted Scarlet',
    '06100': 'Metallic Scarlet',
    '06312': 'Matte Scarlet',
    '07000': 'Maroon',
    '07001': 'Painted Maroon',
    '07100': 'Metallic Maroon',
    '07107': 'Matte Maroon',
    '08000': 'Gold',
    '08001': 'Painted Gold',
    '08002': 'South Bend Gold',
    '08101': 'Metallic Light Gold',
    '08102': 'Metallic Old Gold',
    '08103': 'Metallic Dark Old Gold',
    '08104': 'Metallic Vegas Gold',
    '08105': 'SF Gold',
    '09000': 'Dark Green',
    '09001': 'Painted Dark Green',
    '09100': 'Metallic Dark Green',
    '09206': 'Matte Dark Green',
    '10000': 'Burnt Orange',
    '10001': 'Painted Burnt Orange',
    '10206': 'Matte Burnt Orange',
    '11000': 'Cardinal',
    '11001': 'Painted Cardinal',
    '11004': 'Matte Cardinal',
    '11100': 'Metallic Cardinal',
    '12001': 'Columbia Blue',
    '13000': 'Purple',
    '13001': 'Painted Purple',
    '13100': 'Northwestern Purple',
    '13302': 'Matte Purple',
    '14000': 'Kelly Green',
    '14001': 'Painted Kelly Green',
    '14100': 'Metallic Kelly Green',
    '18000': 'Brown',
    '99993': 'Series 3 Custom',
    '99994': 'Series 4 Custom'
  };
}
