import { Component, OnInit } from '@angular/core';
import { ExpAuthService } from '../exp-auth.service';

@Component({
    selector: 'login-status',
    templateUrl: './login-status.component.html',
    styleUrls: ['./login-status.component.css']
})
export class LoginStatusComponent implements OnInit {

    private authenticated:Boolean;

    constructor(authService:ExpAuthService) {
        this.authenticated = authService.isAuthenticated();
        authService.subscribe(()=>this.authenticated=authService.isAuthenticated());
    }

    ngOnInit() {
    }

}
