import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';

export interface Barcode {
  serial?: string;
  mfg?: moment.Moment;
  retire?: moment.Moment;
}

// const today = new Date();
// const temp = new Date();
// const retire = temp.setFullYear(temp.getFullYear() + 10);


@Injectable({
  providedIn: 'root'
})

export class BarcodeService {
  private barcodeSource = new BehaviorSubject<Barcode>({});
  currentBarcode = this.barcodeSource.asObservable();

  constructor() { }

  changeBarcode(barcode: Barcode) {
    // if it doesn't have a retire date but has a mfg date
    if (!barcode.retire && barcode.mfg) {
      barcode.retire = moment(barcode.mfg).add(10, 'years');
    } else if (!barcode.mfg) {
      barcode.mfg = moment();
      barcode.retire = moment(barcode.mfg).add(10, 'years');
    }
    barcode.serial = this.padBarcode(barcode.serial);
    this.barcodeSource.next(barcode);
  }

  padBarcode(barcode) {
    if (barcode.length < 12) {
      return barcode.padEnd(12 - barcode.length, ' ');
    } else {
      return barcode;
    }
  }
}
