import { Component,OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { ExpAuthService } from '../exp-auth.service';
import { Router, ActivatedRoute, ParamMap, RoutesRecognized } from '@angular/router';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthEvent, EventType } from '../auth-event';

@Component({
    selector: 'exp-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit, OnDestroy {
    returnUrl: string;
    private sub: Subscription;
    private authSub: Subscription;
    private cognito_url:string ='https://xenith.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=7sjgnjpmcnvklc8b1nd61pe3v6&redirect_uri=https%3A%2F%2Flocalhost%3A4200%2Fsso_success';

    @Output()
    event: EventEmitter<AuthEvent> = new EventEmitter<AuthEvent>();

    constructor(private authService: ExpAuthService, private route: ActivatedRoute, private router: Router) {
        let authConfig = authService.getConfig();
        this.cognito_url = `https://${authConfig.ssoDomain}/oauth2/authorize?response_type=code&client_id=${authConfig.clientId}&redirect_uri=${authConfig.redirectUrl}`
    }

    ngOnInit() {
        // this.sub = this.router.events.subscribe(val => {
        //     if (val instanceof RoutesRecognized) {
        //         console.log(val.state.root.firstChild.params);
        //     }
        // });
        this.sub = this.route.queryParamMap.pipe(take(1)).subscribe((paramMap) => this.checkLogin(paramMap));
        // not getting a queryParams, need to call this and save it to local storage before the redirect, maybe in authservice
        // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.returnUrl = sessionStorage.getItem( 'returnUrl') || '/';
    }

    private checkLogin(params: ParamMap) {
        if (this.sub) {
          this.sub.unsubscribe();
        }
        if (! (this.authService.isAuthenticated())) {
            // If the user is not logged in check to see they have a code in the URL
            if (params.has('code')) {
                // console.log('found code:' + params.get('code'));
                // if they have a code, authorize them.
                this.authSub = this.authService.authorize(params.get('code')).subscribe((event) => this.onAuthEvent(event));
                // this.router.navigate(['models']);
            } else {
                // console.log('not authenticated and no code in URL, redirecting to login');
                window.location.href = this.cognito_url;
            }
        } else {
            this.event.emit({type: EventType.LOGIN, message: 'Already Authorized'});
            // this.router.navigate(['fulfillment']);
        }
    }

    private onAuthEvent(event: AuthEvent) {
        if (!event) {
          return;
        }
        if (event.type == EventType.ERROR) {
            alert('Auth ERROR! ' + event.message);
            this.event.emit(event);
        }
        if (event.type == EventType.LOGIN) {
            // I wish this worked, but it doesn't.
            // console.log('rerouting');
            // this.event.emit(event, this.returnUrl);
            this.router.navigate([this.returnUrl]);
        }
    }

    ngOnDestroy() {
        if (this.sub) {
          this.sub.unsubscribe();
        }
        if (this.authSub) {
          this.authSub.unsubscribe();
        }
    }

}
