export class AuthEvent {
    constructor(public type: EventType, public message: string) {}
}

export enum EventType {
    LOGIN,
    LOGOUT,
    ERROR,
    REFRESH
}
