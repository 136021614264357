import { Injectable } from "@angular/core";
import { ColorConstants } from "../../assets/colormap";
import { ModelConstants } from "../../assets/modelmap";

@Injectable({
  providedIn: "root",
})
export class ConfigDecoderService {
  imgSrc = "/src/assets/img/shellmasked2.png";

  constructor() {}

  getBackgroundColor(productColor) {
    if (productColor) {
      const color = ColorConstants.HEX_COLORS[productColor.slice(0, 2)];
      if (!color) {
        return "Black";
      }
      return color;
    } else {
      return "Black";
    }
  }

  getColor(productColor) {
    if (productColor) {
      const color = ColorConstants.XENITH_SHELL_COLORS[productColor];
      if (!color) {
        return `Custom color not in database, Code(${productColor})`;
      }
      return color;
    } else {
      return "Color not in provided";
    }
  }

  getModel(productModel) {
    if (productModel) {
      const model = ModelConstants.Models[productModel.slice(-3)];
      if (!model) {
        // Compensate for NetSuite model pattern
        const components = productModel.split(" ");
        if (components[0] === "Shadow") {
          this.imgSrc = "/src/assets/img/epicMask.png"; // EPIC
        } else if (components[0] === "X2E") {
          this.imgSrc = "/src/assets/img/x2eMask.png"; // X2E
        }

        const isXR = components.some((x) => x === "XR");

        return isXR ? components[0] + " XR" : components[0];
      } else {
        if (productModel.slice(-3) >= 650) {
          this.imgSrc = "/src/assets/img/epicMask.png"; // EPIC
        } else if (productModel.slice(-3) < 650) {
          this.imgSrc = "/src/assets/img/x2eMask.png"; // X2E
        }
        return model.split(" ").slice(0, 1);
      }
    } else {
      return "Model not provided";
    }
  }
  
  getModelWithSize(productModel) {
    if (productModel) {
      const model = ModelConstants.Models[productModel.slice(-3)];
      if (!model) {
        // Compensate for NetSuite model pattern
        const shellModel = productModel.split(" ")[0];
        if (shellModel === "Shadow") {
          this.imgSrc = "/src/assets/img/epicMask.png"; // EPIC
        } else if (shellModel === "X2E") {
          this.imgSrc = "/src/assets/img/x2eMask.png"; // X2E
        }
        return productModel;
      } else {
        if (productModel.slice(-3) >= 650) {
          this.imgSrc = "/src/assets/img/epicMask.png"; // EPIC
        } else if (productModel.slice(-3) < 650) {
          this.imgSrc = "/src/assets/img/x2eMask.png"; // X2E
        }
        return model;
      }
    } else {
      return "Model not saved in Database";
    }
  }
  getSize(productModel) {
    if (productModel) {
      const model = ModelConstants.Models[productModel.slice(-3)];
      if (!model) {
        // Compensate for NetSuite model pattern
        const components = model.split(" ");
        let size;
        let league;
        components.forEach((x) => {
          switch (x) {
            case "Small":
              size = "Small";
              break;
            case "Medium":
              size = "Medium";
              break;
            case "Large":
              size = "Large";
              break;
            case "XL":
              size = "XL";
              break;
            case "Varsity":
              league = "Varsity";
              break;
            case "Youth":
              league = "Youth";
              break;
          }
        });
        return league + " " + size;
      } else {
        return model.split(" ").slice(1).join(" ");
      }
    } else {
      return "Model not saved in Database";
    }
  }
}
