import { Component, OnInit} from '@angular/core';
// import { ProductService } from 'src/app/services/product.service';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { BarcodeService, Barcode } from 'src/app/services/barcode.service';


@Component({
  selector: 'app-barcode-generator',
  templateUrl: './barcode-generator.component.html',
  styleUrls: ['./barcode-generator.component.css']
})

export class BarcodeGeneratorComponent implements OnInit {

  public barcode: Barcode;

  constructor(private barcodeService: BarcodeService) { }

  ngOnInit() {
    this.barcodeService.currentBarcode.subscribe(barcode => {
      this.barcode = barcode;
    });
    // this.eventsSubscription = this.events.subscribe((data) => this.barcodeValue = data );
  }
}
