import { Component, OnInit, Input } from '@angular/core';
import { Order } from 'src/app/order';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { UIService } from 'src/app/services/ui.service';
import { ProductService } from 'src/app/services/product.service';
import { MessageService } from 'src/app/services/message.service';
import { OrderService } from 'src/app/services/order.service';
import { ColorConstants } from 'src/assets/colormap';

@Component({
  selector: 'app-pick-pack-products',
  templateUrl: './pick-pack-products.component.html',
  styleUrls: ['./pick-pack-products.component.css']
})
export class PickPackProductsComponent implements OnInit {
  @Input() order: Order;
  helmetSize;
  isLoading = false;
  private loadingSubs: Subscription;
  deletedSerial;
  boxSize;
  products;
  totalProducts;
  sizes = [
    {value: '8', viewValue: 'XL(8)'},
    {value: '7', viewValue: 'Large(7)'},
    {value: '6', viewValue: 'Medium(6)'},
    {value: '5', viewValue: 'Small(5)'}
  ];

  constructor(
    private uiService: UIService,
    private productService: ProductService,
    private messageService: MessageService,
    private orderService: OrderService) { }

  ngOnInit() {
    this.loadingSubs = this.uiService.loadingStateChanged.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.boxSize = localStorage.getItem('boxSize');
    if (!this.boxSize) {
      this.boxSize = 3;
      localStorage.setItem('boxSize', this.boxSize);
    } else {
      this.boxSize = localStorage.getItem('boxSize');
    }
    this.uiService.boxSize.next(this.boxSize);
    this.products = this.createProducts(this.order, this.boxSize);
    // this.totalProducts = this.products.reduce((sum, el) => sum + (Array.isArray(el) ? sumUp(el) : +el), 0);
    this.totalProducts = this.products.reduce((total, amount) => {
      return total.concat(amount);
    }, []).length;

    // this.products = this.order.operations.map(product => product.productExpId);
  }

  createProducts(order, boxSize) {
    const datesMap = this.makeObjectMap(order.operations.filter(op => op.type === 'PICKPACK'));
    const productsArr = order.products.map(product => {
      // if (product.QC !== 'APPROVED') {
      //   this.messageService.add(
      //     {
      //       type: 'error',
      //       message: `Product: ${product.expId} has not passed QC`,
      //       action: 'delete',
      //       expId: product.expId
      //     });
      // }
      return {'expId': product.expId, 'QC': product.QC, 'operationCreatedAt': datesMap[product.expId]};
    }).sort(this.sortByCreatedAt);

    let arr = [];
    let subArr = [];
    for (let i = 0; i < productsArr.length; i++) {
      subArr.unshift(productsArr[i]);
      if ((i + 1) % boxSize === 0) {
        arr.unshift(subArr);
        subArr = [];
      } else if (i + 1 === productsArr.length) {
        arr.unshift(subArr);
      }

    }
    return arr;
  }
  updateHelmetSize() {
    this.productService.helmetSizeforPP = this.helmetSize;
  }
  onBoxSizeChange(boxSize) {
    if (boxSize > 0 && boxSize < 10) {
      localStorage.setItem('boxSize', boxSize);
      this.uiService.boxSize.next(this.boxSize);
      this.products = this.createProducts(this.order, boxSize);
    }
  }

  makeObjectMap(arr) {
    let map = {};
    for (var i = 0; i < arr.length; ++i) {
      map[arr[i].productExpId] = arr[i].createdAt;
    }
    return map;
  }
  sortByCreatedAt( a, b ) {
    if ( a.operationCreatedAt < b.operationCreatedAt ) {
      return 1;
    }
    if ( a.operationCreatedAt > b.operationCreatedAt ) {
      return -1;
    }
    return 0;
  }

  deleteSerials(items) {
    // console.log(items);
    let expIds = [];
    for (let item of items) {
      expIds.push(this.order.operations.filter(product => product.productExpId == item.expId).map(op => op.expId)[0]);
    }
    // console.log(expIds);
    this.deletedSerial = items[0].expId;
    this.productService.deleteOperations(expIds)
    .subscribe(responses => {
      for (let response of responses) {
        if (response.success) {
          // console.log(JSON.stringify(response));
          this.messageService.add({type: 'success', message: `Deleted helmet`});
        } else if (!response.success) {
          this.messageService.add({type: 'alert', message: 'Couldn\'t reach API to delete, try again later'});
        }
      }
      this.orderService.selectOrder(this.order.expId);
    });
 }
  getColor(productColor) {
    if(productColor) {
        const color = ColorConstants.XENITH_SHELL_COLORS[productColor];
      if (!color) {
        return 'Custom Color';
      }
      return color;
      } else {
        return 'Color not in Database';
    }
  }
  getBackgroundColor(productColor) {
    if(productColor){
      const color = ColorConstants.HEX_COLORS[productColor.slice(0, 2)];
    if (!color) {
      return 'Black';
    }
    return color;
    } else {
      return 'Black';
    }
  }
}
