import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { SlackService } from '../services/slack.service';
import { ExpAuthService } from '../exp-auth/exp-auth.module';
import { MessageService } from '../services/message.service';

export interface DialogData {
  message: string;
  name: string;
}


@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  CS = 'CUSTOMER SERVICE';
  AS = 'ASSEMBLY';
  RB = 'RECON BARCODES';
  RA = 'RECON ASSEMBLY';
  message: string;
  name: string;
  qcStatus = false;

  constructor(
    public dialog: MatDialog,
    public slack: SlackService,
    private authService: ExpAuthService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.evalQCStatus();
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewComponent, {
      data: {name: this.name, message: this.message},
      height: '500px',
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && 'message' in result) {
        this.postMessage(result);
      }
    });
  }

  copyEmail() {
    // window.location.assign(`mailto:xtech@xenith.com?Subject=Serial%20Question`);
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = 'xtech@xenith.com';
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.messageService.add({type: 'success', message: 'Xtech@xenith.com copied to clipboard'});
  }
  postMessage(message) {
    const postString = `${message.name} said: ${message.message}`;
    this.slack.postSerialSlack(postString);
  }
  evalQCStatus() {
    this.qcStatus = this.authService.evalGroupStatus('QC');
  }
}
@Component ({
  selector: 'app-dialog-overview',
  templateUrl: 'dialog.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogOverviewComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewComponent>,
    @Inject (MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
