import { Component, OnInit, Input } from '@angular/core';
import { OrderService } from 'src/app/services/order.service';
import { MessageService } from 'src/app/services/message.service';
import { QcService } from 'src/app/services/qc.service';

@Component({
  selector: 'app-qc-widget',
  templateUrl: './qc-widget.component.html',
  styleUrls: ['./qc-widget.component.css']
})
export class QcWidgetComponent implements OnInit {
  // @Input() workOrder;
  // passing = 0;
  // total;

  constructor(public qcService: QcService) { }

  ngOnInit() {
    // this.qc.getOrderByOrderForQC(this.workOrder.split('-')[0]).subscribe(response => {
    //   if (response.success) {
    //     this.total = response.body.helmetQuantity;
    //     const qcArray = response.body.operations.filter(operation=>operation.type == "QC").map(obj => obj.QCs[0]);
    //     for (let qcOp of qcArray) {
    //       if(qcOp && qcOp.status === 'APPROVED') {
    //         this.passing++;
    //       }
    //     }
    //     console.log(`Response --> ${JSON.stringify(response)}`);

    //   } else {
    //     this.messageService.add({type: 'warn', message: response.error});
    //   }
    // });
  }
  close() {
    this.qcService.clear();
  }

}
