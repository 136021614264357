import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MessageService } from './message.service';
import { headersToString } from 'selenium-webdriver/http';
import { environment } from 'src/environments/environment';


// const httpOptions = {
//   headers: new HttpHeaders({responseType: 'text'})
// };

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Authorization': ''
//   })
// };

@Injectable({
  providedIn: 'root'
})

export class SlackService {

  private slackUrl = environment.slackSerialUrl;

  constructor(private http: HttpClient, private messageService: MessageService) { }

  postSerialSlack(incomingMessage) {
    const payload = JSON.stringify({text: incomingMessage});

    this.http.post<any>(this.slackUrl, payload, {}).subscribe(response => {
      if (response) {
        console.log(response);
      }
    });
  }
}
