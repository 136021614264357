import { Injectable } from '@angular/core';
import {Howl, Howler} from 'howler';

export class Message {
  id?: number;
  type: 'success' | 'alert' | 'error' | 'warn';
  message: string;
  action?: string;
  expId?: string;
}

@Injectable({
  providedIn: 'root'
})



export class MessageService {

  failSound = new Howl({
    src: ['../../../assets/sounds/fail.wav']
  });

  messages: Message[] = [];
  localLog: string;
  // previousMessage: Message = {type: '', message: ''};

  add(message: Message) {

    if (message.type === 'alert') {
      window.alert(message.message);
      return;
    } else if (message.type === 'error') {
      this.failSound.play();
    }
    if (!message.id) {
      message.id = new Date().valueOf();
    }
    this.messages.push(message);

    // Maybe add this local logging later
   // this.updateLocalLog(message);

    if (message.type === 'success' || message.type === 'warn') {
      this.clear(message.id);
    }
  }

  clear(id) {
    setTimeout(() => { this.removeMessage(id); }, 10000);
  }

  updateLocalLog(message) {
    let localString = localStorage.getItem('localLog');
    message = this.addCognitoUsername(message);
    localString = localString + '  ' + JSON.stringify(message); // tab delimit each object
    localStorage.setItem('localLog', localString);
  }

  addCognitoUsername(message) {
    const username = JSON.parse(localStorage.getItem('token'))['cognito:username'];
    message['username'] = username;
    return message;
  }

  retrieveLocalLog() {
    return localStorage.getItem('localLog');
  }
  clearLocalLog() {
    localStorage.setItem('localLog', '');
  }

  removeMessage(id) {
    this.messages = this.messages.filter(message => message.id !== id);
  }
}
