import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { ExpAuthService } from './exp-auth.service';
import { Observable } from 'rxjs';



@Injectable()
export class ExpAuthInterceptor implements HttpInterceptor {


    private authService: ExpAuthService;

    constructor(private injector: Injector) {
    }

    getAuthService(): ExpAuthService {
        if (!this.authService) {
          this.authService = this.injector.get(ExpAuthService);
        }
        return this.authService;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = localStorage.getItem('token');

        // if (token) {
        //   request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        // }

        const authService = this.getAuthService();
        if (request.url.startsWith('https://hooks.slack.com/')) {
          return next.handle(request);
        }
        if (authService.isAuthenticated() && request.headers.get('Authorization') === null) {
            request = request.clone({setHeaders: {Authorization: authService.getAccessToken()}});
        }
        return next.handle(request);
    }

}
