import { NgModule, ModuleWithProviders, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { ExpAuthService } from './exp-auth.service';
import { ExpAuthConfig } from './exp-auth-config';
import { LoginStatusComponent } from './login-status/login-status.component';

export { AuthEvent, EventType } from './auth-event';
export { ExpAuthConfig } from './exp-auth-config';
export { ExpAuthService } from './exp-auth.service';
export { ExpAuthInterceptor } from './exp-auth-interceptor';
export { LoginComponent };

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        LoginComponent,
        LoginStatusComponent
    ],
    declarations: [
        LoginComponent,
        LoginStatusComponent
    ],
    providers: []
})
export class ExpAuthModule {
  constructor() {}

  public static forRoot(config: ExpAuthConfig): ModuleWithProviders {
    return {
      ngModule: ExpAuthModule,
      providers: [{
        provide: ExpAuthService,
        useFactory: ExpAuthModule.getProviderFactory(config),
        deps: [HttpClient]
      }]
    };
  }
    public static getProviderFactory(config: ExpAuthConfig) {
      // return providerForNow(http, config);
        return (http: HttpClient) => {
            return new ExpAuthService(http, config);
        };
    }

}

// export function providerForNow(http: HttpClient, config: ExpAuthConfig) {
//     return new ExpAuthService(http, config);
// }
