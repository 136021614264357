import { Injectable } from '@angular/core';

export interface configuration {
  groupName: string;
  filter: string;
  rootUrl: string;
  orderType: Array<String>;
  printBool: boolean;
  serial?: string;
  color?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AssemblyViewService {
  public recon: configuration = {
    groupName: 'QC', // <---- change me!
    filter: 'Recon',
    rootUrl: '/recon/assembly/',
    orderType: ['RC'],
    printBool: false,
    serial: 'Serial #',
    color: '#005299' // Blue
  };
  public pb: configuration = {
    groupName: 'QC', // <---- change me!
    filter: 'Prebuild',
    rootUrl: '/pb/assembly/',
    orderType: ['ASM'],
    printBool: true,
    serial: 'NC#',
    color: '#3D9970' // Olive
  };
  public pp: configuration = {
    groupName: 'QC', // <---- change me!
    filter: 'PICKPACK',
    rootUrl: '/pick-pack/assembly/',
    orderType: ['ORD', 'TRF'],
    printBool: false,
    serial: 'NC#',
    color: '#a3816a' // Brown
  };
  public standard: configuration = {
    groupName: 'QC', // <---- change me!
    filter: 'Assembly',
    rootUrl: '/new-order/',
    orderType: ['ORD'],
    printBool: true,
    serial: 'NC#',
    color: '#FFC107' // Yellow
  };
  constructor() { }


}
