import { Component, OnInit } from '@angular/core';
import { MessageService } from '../services/message.service';
import { ProductService } from '../services/product.service';
// import { OrderService } from '../services/order.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {

  constructor(public messageService: MessageService, private productService: ProductService) { }

  ngOnInit() {
  }

  deleteSerials(item) {
    this.productService.deleteOperations([item])
    .subscribe(responses => {
      for (let response of responses) {
        if (response.success) {
          // console.log(JSON.stringify(response));
          this.messageService.add({type: 'success', message: `Deleted helmet`});
        } else if (!response.success) {
          this.messageService.add({type: 'error', message: 'Couldn\'t reach API to delete, try again later'});
        }
      }
      // this.orderService.selectOrder(this.order.expId);
    });
  }
  close(id) {
    this.messageService.removeMessage(id);
  }
}
