import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { Product } from './../product';
import { OrderService } from './order.service';
import { MessageService } from './message.service';
import { UIService } from './ui.service';
import { QcService } from './qc.service';



const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public helmetSizeforPP;
  private POSTProductUrl = 'custom/createProductForAssembly?';
  private POSTPreBuildProductUrl = 'custom/createProductForPrebuild?';
  private POSTQCUrl = 'crud/QC/create?';
  private POSTReconReplacement = 'custom/reconReplacement';
  private POSTFlagProduct = 'custom/flagProduct?';
  private POSTReconProductUrl = 'custom/upsertProductForRecon?';
  private POSTOperationUrl = 'crud/operation/create?';
  private DELETEProductUrl = 'crud/product/delete';
  private DELETEOperationUrl = 'crud/operation/delete';
  private expProductDetailUrl = 'crud/product/read/';


    private productDetailUrl = environment.expSerialBaseUrl + this.expProductDetailUrl;
    private addHelmetUrl = environment.expSerialBaseUrl + this.POSTProductUrl + environment.expSerailToken;
    private addnocsaeUrl = environment.expSerialBaseUrl + 'crud/product/upsert?' + environment.expSerailToken;
    private deleteHelmetBaseUrl = environment.expSerialBaseUrl + this.DELETEProductUrl;
    barcodeValue = '';
    private deleteOperationBaseUrl = environment.expSerialBaseUrl + this.DELETEOperationUrl;
    private addQCOperation = environment.expSerialBaseUrl + this.POSTQCUrl;
    private reconReplace = environment.expSerialBaseUrl + this.POSTReconReplacement;
    private flagProduct = environment.expSerialBaseUrl + this.POSTFlagProduct;
    private addPrebuildProductUrl = environment.expSerialBaseUrl + this.POSTPreBuildProductUrl;
    private createOperation = environment.expSerialBaseUrl + this.POSTOperationUrl;
    private addReconProductUrl = environment.expSerialBaseUrl + this.POSTReconProductUrl;

    constructor(
      private http: HttpClient,
      private orderService: OrderService,
      private messageService: MessageService,
      private uiService: UIService,
      private qcService: QcService
    ) { }

    getProductDetails(id: string): Observable<any> {
      return this.http.post<Product>(this.productDetailUrl + id + '?' + environment.expSerailToken, {
        'options': {
          'include': ['ProductConfig', 'Operation->QC']
        }
      });
    }

    // lookupProductWithObservable(product: Product): void {
    //   this.http.post<any>(this.addHelmetUrl, product, httpOptions).subscribe(response => {
    //     if (response.succes) {
    //       response.body.qcStatus = response.body.qcStatus.sort(this.compare)[0];
    //     }
    //   });
    // }

    compare(a, b) {
      if (a.createdAt > b.createdAt) {return -1; }
      if (a.createdAt < b.createdAt) {return 1; }
      return 0;
    }

    addProductWithObservable(product): Observable<any> {
      // console.log('Productas from product service is -->:' + JSON.stringify(product));
      this.uiService.savvingHelmet.next(true);
      return this.http.post<any>(this.addHelmetUrl, product, httpOptions).pipe(
        catchError(this.handleError<any>('addOrderwith Observable'))
      );
    }

    addnocsaeProductWithObservable(product): Observable<any> {
      // console.log('Productas from product service is -->:' + JSON.stringify(product));
      // this.uiService.savvingHelmet.next(true);
      return this.http.post<any>(this.addnocsaeUrl, product, httpOptions).pipe(
        catchError(this.handleError<any>('addOrderwith Observable'))
      );
    }

    addPreBuildProductWithObservable(product): Observable<any> {
      this.uiService.savvingHelmet.next(true);
      return this.http.post<any>(this.addPrebuildProductUrl + environment.expSerailToken, product, httpOptions).pipe(
        catchError(this.handleError<any>('addOrderwith Observable'))
      );
    }

    addpickAndPackProducts(products): Observable<any> {
      this.uiService.savvingHelmet.next(true);
      return this.http.post(this.createOperation, products, httpOptions);
    }

    addReconProductWithObservable(product): Observable<any> {
      this.uiService.savvingHelmet.next(true);
      return this.http.post<any>(this.addReconProductUrl, product, httpOptions).pipe(
        catchError(this.handleError<any>('addOrderwith Observable'))
      );
    }

    createQCOperation(body) {
      this.http.post<any>(this.addQCOperation, body, httpOptions).subscribe(response => {
        if (response.success) {
          if (response.body.subStatus) {
            this.messageService.add({type: 'warn', message: `QC Status updated to: ${response.body.status} ${response.body.subStatus}`});
          } else {
            this.messageService.add({type: 'success', message: `QC Status updated to: ${response.body.status}`});
          }
        } else {
          console.log(`error`);
          this.messageService.add({type: 'error', message: response.error});
        }
        this.qcService.updateValues();
      });
    }

    deleteSerial(deleteObj): void {
      this.uiService.loadingStateChanged.next(true);
      const deleteURL = this.deleteHelmetBaseUrl + '/' + encodeURIComponent(deleteObj.expID);
      this.http.delete<Product>(deleteURL, httpOptions).subscribe(response => {
        if (response) {
          // this.uiService.loadingStateChanged.next(false);
          this.messageService.add({type: 'success', message: `Deleted helmet ${deleteObj.expId}`});
          this.orderService.selectOrder(deleteObj.orderId);
        } else {
          this.uiService.loadingStateChanged.next(false);
          this.messageService.add({type: 'error', message: 'Couldn\'t reach API to delete, try again later'});
        }
      });
    }

    deleteOperations(deleteArr) {
      let observableBatch = [];
      deleteArr.forEach((product) => {
        observableBatch.push( this.http.delete(this.deleteOperationBaseUrl + '/' + product, httpOptions));
      });
      return forkJoin(observableBatch);
    }

    replaceBarcode(barcode): Observable<any> {
      return this.http.post<any>(this.reconReplace, barcode, httpOptions).pipe(
        catchError(this.handleError<any>('Recon Replace with Observable'))
      );
    }

    flagProductwithObservable(body): Observable<any> {
      return this.http.post(this.flagProduct, body, httpOptions).pipe(
        catchError(this.handleError<any>('Flag Product with Observable'))
      );
    }

    print(serialId) {
      this.barcodeValue = serialId;
      setTimeout(() => { window.print(); }, 300);
    }

    private handleErrorObservable (error: Response | any) {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }

    private handleErrorPromise (error: Response | any) {
        console.error(error.message || error);
        return Promise.reject(error.message || error);
    }

    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
        console.error(error);
        this.messageService.add({type: 'error', message: error.message});
        return of(result as T);
      };
    }
  }
