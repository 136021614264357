import { Component, OnInit } from '@angular/core';
import { AssemblyViewService, configuration } from 'src/app/services/assembly-view.service';

@Component({
  selector: 'app-recon-assembly-view',
  templateUrl: './recon-assembly-view.component.html',
  styleUrls: ['./recon-assembly-view.component.css']
})
export class ReconAssemblyViewComponent implements OnInit {
  configuration: configuration;

  constructor(private view: AssemblyViewService) { }

  ngOnInit() {
    this.configuration = this.view.recon; // Need to set for each view
  }

}
