import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router, RouterEvent, NavigationEnd, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ExpAuthService } from 'src/app/exp-auth/exp-auth.module';
import { configuration } from 'src/app/services/assembly-view.service';

@Component({
  selector: 'app-assembly',
  templateUrl: './assembly.component.html',
  styleUrls: ['./assembly.component.css']
})
export class AssemblyComponent implements OnInit, OnDestroy {
  id;
  urlId;
  routerSub;
  group;
  batch;
  @Input() configuration: configuration;

  constructor(
    private authService: ExpAuthService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    // this.configuration = this.view.pb; // Need to set for each view

    this.evalGroupStatus();
    this.routerSub = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // this.getOrder();
        this.id = this.route.snapshot.paramMap.get('id');
      }
    });
    this.id = this.route.snapshot.paramMap.get('id');
    this.id ? this.urlId = true : this.urlId = false;
  }
  receiveBatchfromChild($event) {
    this.batch = $event;
  }
  evalGroupStatus() {
    // change to recon group when you create it
    this.group = this.authService.evalGroupStatus(this.configuration.groupName);
  }
  ngOnDestroy() {
    this.routerSub.unsubscribe();
  }


}
