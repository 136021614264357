import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ExpAuthService } from 'src/app/exp-auth/exp-auth.module';
import { ProductService } from 'src/app/services/product.service';
import { MessageService } from 'src/app/services/message.service';
import { QCCodes } from '../../../assets/qcmap';
import { t } from '@angular/core/src/render3';
import { QcService } from 'src/app/services/qc.service';

// export interface State {
//   value: string;
//   viewValue: string;
// }

@Component({
  selector: 'app-quality-approval',
  templateUrl: './quality-approval.component.html',
  styleUrls: ['./quality-approval.component.css']
})
export class QualityApprovalComponent implements OnInit {
  // options: State[] = [
  //   {value: 'hold-fail', viewValue: 'Fail'},
  //   {value: 'hold-paint', viewValue: 'Paint'},
  //   {value: 'hold-billing', viewValue: 'Billing'},
  //   {value: 'hold-other', viewValue: 'Other'}
  // ];
  // wrong = ['Snap Buckles', 'Mask', 'Warning Label', 'Shell Color', 'Paint Color'];
  wrong = QCCodes.WRONG;
  missing = QCCodes.MISSING;
  damaged = QCCodes.DAMAGED;
  other = QCCodes.OTHER;

  // Translations
  QCTITLE = 'Quality Control';
  APPROVE = 'APPROVE';
  Wrong = 'Wrong';
  Missing = 'Missing';
  Damaged = 'Damaged';
  Other = 'Other';

  serialId;
  decodedToken;
  qcGroup = false;
  inputQCStatus = {};
  @Input() qcStatus;

  @Output() orderEvent = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: ExpAuthService,
    private productService: ProductService,
    private messageService: MessageService,
    private qcService: QcService
    ) {}

  ngOnInit() {
    this.serialId = this.route.snapshot.paramMap.get('id');
    this.decodedToken = this.authService.decodedToken;
    this.evalQCStatus();
    // console.log(this.qcStatus);
  }


  approve() {

    this.updateProduct({status: 'APPROVED', subStatus: null});
  }

  deny(code, value) {
    const dbValue = { 'status': code, 'subStatus': value};
    // const dbValue = code + '-' + value;
    this.updateProduct(dbValue);
  }

  updateProduct(value) {
    // if (value.status !== this.qcStatus.status && value.substatus !== this.qcStatus.substatus) {
    if (value.status) {
      this.productService.createQCOperation(
        {
          'object': {
            'productExpId': this.serialId,
            'qcStatus': value,
            'email': this.decodedToken.email,
            'name': this.decodedToken.name,
          },
          'options': {
            'include': []
          }
        }
      );
      this.orderEvent.emit(value);
      this.router.navigate(['/new-order']);
    } else {
      console.log(`That QCStatus is already assigned`);
    }
  }

  evalQCStatus() {
    this.qcGroup = this.authService.evalGroupStatus('QC');
    // if (
    //   decodedToken && decodedToken['cognito:groups']
    //   &&
    //   (decodedToken['cognito:groups'].indexOf('SerialAdmin') !== -1 || decodedToken['cognito:groups'].indexOf('QC') !== -1)
    //   ) {
    //     this.qcGroup = true;
    //   }
  }

  customQCStatus() {
    this.updateProduct(this.inputQCStatus);
  }
}
