export class ScanPaths {
  public static PATHS = {
    order: {'prefix': 'OR', 'length': 31 },
    recon: {'prefix': 'RC', 'length': 7 },
    reconTemp: { 'prefix': 'RC', 'length': 28 },
    serial: {'prefix': 'NC', 'length': 12 },
    oldSerial: {'prefix': 'S', 'length': 11 },
    ras: {'prefix': 'RA', 'length': 7 }
  };
}
