import { Pipe, PipeTransform } from '@angular/core';
import { ConfigDecoderService } from '../services/config-decoder.service';

export type Methods = 'model'|'color'|'size'|'modelSize';

@Pipe({
  name: 'decoder'
})

// public enum Methods {'model', 'color', 'size'}


export class DecoderPipe implements PipeTransform {

  constructor(private decoder: ConfigDecoderService) {}
  transform(value: string, method: Methods): string {
    switch (method) {
      case 'model':
        return this.decoder.getModel(value);
      case 'color':
        return this.decoder.getColor(value);
      case 'size':
        return this.decoder.getSize(value);
      case 'modelSize':
        return this.decoder.getModelWithSize(value);
    }
  }

}
