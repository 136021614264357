import { Component, OnInit, Input } from '@angular/core';
import { ColorConstants } from '../../../assets/colormap';
import { ModelConstants } from '../../../assets/modelmap';

@Component({
  selector: 'app-config-details',
  templateUrl: './config-details.component.html',
  styleUrls: ['./config-details.component.css']
})
export class ConfigDetailsComponent implements OnInit {
  DEETS = 'CONFIGURATION DETAILS';

  @Input() productColor: String;
  @Input() productModel: String;

  imgSrc = '/assets/img/shellmasked2.png'; // returns shadow by default
  constructor() { }

  ngOnInit() {
  }

  getBackgroundColor(productColor) {
    if (productColor) {
      const color = ColorConstants.HEX_COLORS[productColor.slice(0, 2)];
    if (!color) {
      return 'Black';
    }
    return color;
    } else {
      return 'Black';
    }
  }

  getColor(productColor) {
    if(productColor) {
        const color = ColorConstants.XENITH_SHELL_COLORS[productColor];
      if (!color) {
        return productColor;
      } else {
        return color;
      }
    }

  }

  getModel(productModel) {
    if (productModel) {
      const model = ModelConstants.Models[productModel.slice(-3)];
      if (!model) {
        // Compensate for NetSuite model pattern
        const components = productModel.split(" ");
        if (components[0] === "Shadow") {
          this.imgSrc = "/assets/img/shellmasked2.png"; // Shadow
        } else if (components[0] === "X2E") {
          this.imgSrc = "/assets/img/x2eMask.png"; // X2E
        } else {
          this.imgSrc = "/assets/img/epicMask.png"; // EPIC
        }

        return productModel;
      } else {
        if (productModel.slice(-3) > 780) {
          this.imgSrc = '/assets/img/shellmasked2.png'; // Shadow
        } else if (productModel.slice(-3) < 650) {
          this.imgSrc = '/assets/img/x2eMask.png'; // X2E
        } else {
          this.imgSrc = '/assets/img/epicMask.png'; // EPIC
        }
        return model;
      }
    } else {
      return 'Model not saved in Database';
    }
  }
}
