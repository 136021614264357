import { Component, OnInit } from '@angular/core';
import { configuration, AssemblyViewService } from 'src/app/services/assembly-view.service';

@Component({
  selector: 'app-pick-pack-assembly',
  templateUrl: './pick-pack-assembly.component.html',
  styleUrls: ['./pick-pack-assembly.component.css']
})
export class PickPackAssemblyComponent implements OnInit {
  configuration: configuration;

  constructor(private view: AssemblyViewService) { }

  ngOnInit() {
    this.configuration = this.view.pp; // Need to set for each view
  }

}
