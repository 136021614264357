import { Component, OnInit } from '@angular/core';
import { configuration, AssemblyViewService } from 'src/app/services/assembly-view.service';


@Component({
  selector: 'app-assembly-view',
  templateUrl: './assembly-view.component.html',
  styleUrls: ['./assembly-view.component.css']
})
export class AssemblyViewComponent implements OnInit {
  configuration: configuration;

  constructor(private view: AssemblyViewService) { }

  ngOnInit () {
    this.configuration = this.view.standard; // Need to set for each view
  }
}
