// import { Directive, ElementRef, AfterViewInit, AfterViewChecked } from '@angular/core';

// @Directive({
//   selector: '[appAutofocus]'
// })
// export class AutofocusDirective implements AfterViewInit, AfterViewChecked {

//   constructor(private el: ElementRef) {
// 		// el.nativeElement.style.backgroundColor = 'yellow';
// 	}
//   ngAfterViewInit() {
//     this.el.nativeElement.focus();
//   }

//   ngAfterViewChecked() {
//     this.el.nativeElement.focus();
//   }

// }
import {Directive, ElementRef, Inject, Input, OnChanges, OnInit, Renderer, AfterViewChecked, AfterViewInit} from '@angular/core';

@Directive({
  selector: '[focus]'
})

export class AutofocusDirective implements OnChanges, OnInit, AfterViewChecked, AfterViewInit {
  @Input()focus: boolean;

  constructor(@Inject(ElementRef) private element: ElementRef, public renderer: Renderer) {}

  ngOnInit() {
    this.renderer.invokeElementMethod(this.element.nativeElement,   'focus', []);
  }
  ngAfterViewInit() {
    this.renderer.invokeElementMethod(this.element.nativeElement,   'focus', []);
  }
  ngAfterViewChecked() {
    this.renderer.invokeElementMethod(this.element.nativeElement,   'focus', []);
  }
  public ngOnChanges() {
  this.element.nativeElement.focus();
  this.renderer.invokeElementMethod(this.element.nativeElement,   'focus', []);
  }

}
