import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ExpAuthService } from './exp-auth.module';

@Injectable()
export class ExpAuthGuard implements CanActivate {

  constructor(private authService: ExpAuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // console.log('Checking isAuthenticated()');
    if (this.authService.isAuthenticated()) {
      // console.log(`return url from auth guard is ${state.url}`);
      return true;
    } else {
      // console.log(`return url from auth guard is ${state.url}`);
      sessionStorage.setItem( 'returnUrl', state.url);
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});

      return false;
    }
  }
}
