import { Injectable } from '@angular/core';
import { OrderService } from './order.service';
import { MessageService } from './message.service';
import { SlackService } from './slack.service';

@Injectable({
  providedIn: 'root'
})
export class QcService {
  constructor(
    private orderService: OrderService,
    private messageService: MessageService,
    private slackService: SlackService) { }
  workOrder;
  passing = 0;
  total;

  getQCNumbers(orderNum) {

    if (!orderNum) {
      return this.messageService.add({type: 'error', message: 'Work Order has not been saved to DB, contact XTech to resolve'});
      // this.slackService.postSerialSlack(`Work Order ${}`)
    }
    this.resetValues();
    this.workOrder = orderNum.split('-')[0];

    this.orderService.getOrderByOrderForQC(orderNum.split('-')[0]).subscribe(response => {
      if (response.success) {
        this.total = response.body.helmetQuantity;
        const qcArray = response.body.operations.filter(operation => operation.type == 'QC').map(obj => obj.QCs[0]);
        this.passing = 0;
        for (let qcOp of qcArray) {
          if (qcOp && qcOp.status === 'APPROVED') {
            this.passing++;
          }
        }
      } else {
        this.messageService.add({type: 'warn', message: response.error});
      }
    });
  }

  clear() {
    this.workOrder = null;
  }
  updateValues() {
    // console.log(this.workOrder);
    if (this.workOrder) {
      this.getQCNumbers(this.workOrder);
    }
  }
  setWorkOrder(workOrder) {
    this.workOrder = workOrder;
    this.getQCNumbers(this.workOrder);
  }
  resetValues() {
    this.workOrder = null;
    this.passing = null;
    this.total = undefined;
  }


}
