import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, RouterLinkWithHref } from '@angular/router';
import { MessageService } from 'src/app/services/message.service';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  searchInput = '';
  searchedItem = '';
  isOpen = true;

  @Output() messageEvent = new EventEmitter<string>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public messageService: MessageService
    ) { }

  ngOnInit() {
    this.searchedItem = this.route.snapshot.paramMap.get('id');
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  onSearch(value) {
    value = value.replace(/[^a-z0-9\.+]/gi, '').toUpperCase();
    if (value) {
      this.toggle();
      this.messageEvent.emit(value);
      this.searchedItem = value;
      this.searchInput = null;
      // switch (value.slice(0, 1).toUpperCase()) {
      //   case 'N':
      //   case 'S':
      //   case 'O':
      //   case 'A':
      //   case 'R':
      //     this.toggle();
      //     this.messageEvent.emit(value);
      //     this.searchedItem = value;
      //     this.searchInput = null;
      //     break;
      //   default:
      //     this.messageService.add({type: 'error', message: `${value} is not a valid helmet or order`});
      // }
    }
  }
}
