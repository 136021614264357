import { Component, OnInit } from '@angular/core';
import { AssemblyViewService, configuration } from 'src/app/services/assembly-view.service';

@Component({
  selector: 'app-pb-assembly-view',
  templateUrl: './pb-assembly-view.component.html',
  styleUrls: ['./pb-assembly-view.component.css']
})
export class PbAssemblyViewComponent implements OnInit {
  configuration: configuration;

  constructor(private view: AssemblyViewService) { }

  ngOnInit() {
    this.configuration = this.view.pb; // Need to set for each view
  }
}
