import { Component, OnInit, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material';
import { Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MessageService } from 'src/app/services/message.service';
import { OrderService } from 'src/app/services/order.service';
import { ScanPaths } from 'src/assets/scanMap';


@Component({
  selector: 'app-recon-order',
  templateUrl: './recon-order.component.html',
  styleUrls: ['./recon-order.component.css']
})
export class ReconOrderComponent implements OnInit {
  @ViewChild('orderConfigScanBox') nameInput: MatInput;

  FIND = 'Find Order or Serial Number';
  private PATHS = ScanPaths.PATHS;
  private scanValues = new Subject<string>();
  scannedOrderConfig$: Subscription;

  constructor(
    private orderService: OrderService,
    private router: Router,
    private messageService: MessageService
    ) { }

  ngOnInit() {
    this.nameInput.focus();

    this.scannedOrderConfig$ = this.scanValues.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(term => this.addOrder(term));
  }

  addOrder(term): void {
    if (this.validateOrder(term)) {
      this.orderService.addOrderWithObservable(this.configureOrder(term))
      .subscribe(response => {
        if (response.success) {
          this.router.navigateByUrl(`/order/${response.body.expId}`);
        }
      });
    }
  }

  validateOrder(term): boolean {
    // console.log(`validating order`);
    // check to see if they have finished typing (approximately)
    const input = term.split('-');
    // check to see that the barcode is formatted correctly
    if (input.length < 6) {
      return false;
    } else if (input.length !== 6) {
      this.messageService.add({type: 'error', message: 'The barcode is not formatted correctly'});
    } else {
      return true;
    }
  }

  configureOrder(term) {
    const order = {
      expId: term.split('-')[0] + '-' + term.split('-')[1],
      assemblyDate: '',
      orderNumber: term.split('-')[0],
      configKey: term.split('-')[1],
      productModel: term.split('-')[2],
      productColor: term.split('-')[3],
      productQuantity: term.split('-')[4],
      zipCode: term.split('-')[5],
      products: []
    };
    return order;
  }

  onOrderConfigBarCodeEntry(term: string) {
    if (term.slice(0, 2) === this.PATHS.recon.prefix && term.length >= this.PATHS.recon.length ) {
      this.router.navigateByUrl('/recon/' + term);
    }
  }
}
