import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Order } from '../../order';
import { ExpAuthService } from 'src/app/exp-auth/exp-auth.module';
import { MessageService } from 'src/app/services/message.service';
import { UIService } from 'src/app/services/ui.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-scanned-helmets',
  templateUrl: './scanned-helmets.component.html',
  styleUrls: ['./scanned-helmets.component.css']
})
export class ScannedHelmetsComponent implements OnInit, OnDestroy {
  SCAN = 'Saved Helmets';
  isLoading = false;
  isSaving = false;
  deletedSerial;
  private loadingSubs: Subscription;
  private savingProductSubs: Subscription;

  @Input() order;
  @Output() OnrePrint = new EventEmitter();
  @Output() OnDelete = new EventEmitter();
  decodedToken;

  constructor(
    private authService: ExpAuthService,
    private messageService: MessageService,
    private uiService: UIService
    ) {
    this.decodedToken = authService.decodedToken;
  }

  ngOnInit() {
    this.loadingSubs = this.uiService.loadingStateChanged.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.savingProductSubs = this.uiService.savvingHelmet.subscribe(isSaving => {
      this.isSaving = isSaving;
    });
  }


  rePrint(serialId) {
    this.OnrePrint.emit(serialId);
  }

  deleteSerial(product) {
    const deleteObj = {expID: product.expId, orderId: this.order.expId, expId: product.expId};
    if (this.decodedToken && this.decodedToken['cognito:groups'] && this.decodedToken['cognito:groups'].indexOf('SerialAdmin') !== -1) {
      this.OnDelete.emit(deleteObj);
      this.deletedSerial = product.expId;
    } else {
      this.messageService.add({type: 'error', message: 'You must be signed in as an admin to delete helmets'});
    }
  }

  ngOnDestroy() {
    this.loadingSubs.unsubscribe();
    this.savingProductSubs.unsubscribe();
  }
}
