import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MessageService } from 'src/app/services/message.service';
import { NgForm } from '@angular/forms';
import { ProductService } from 'src/app/services/product.service';
import { SlackService } from 'src/app/services/slack.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-flagger',
  templateUrl: './flagger.component.html',
  styleUrls: ['./flagger.component.css']
})
export class FlaggerComponent implements OnInit {
  @Input() product;

  // @Output() submitEvent = new EventEmitter<string>();
  // Flagging functionality
  notClicked = true;
  selected;

  // Values are defined in backend.
  flags = [
    {value: 'DEACTIVATED', viewValue: 'Deactivated'},
    {value: 'RECALLED', viewValue: 'Recalled'},
    {value: 'FRAUDULENT', viewValue: 'Fraudulent'},
    {value: 'RETURNED', viewValue: 'Returned'},
    {value: 'Other', viewValue: 'Other'}
  ];

  constructor(
    private messageService: MessageService,
    private productService: ProductService,
    private slackService: SlackService,
    private route: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit() {
    console.log(JSON.stringify(this.route.url));
  }

  toggleFlagForm() {
    this.notClicked = !this.notClicked;
  }

  submitFlag(form: NgForm) {
    if (form.value.flagValue === 'Other' && !form.value.otherValue) {
      return this.messageService.add({type: 'warn', message: 'You need to type in a value for "other" if you select other'});
    }
    const r = confirm('Are you sure you want to flag this helmet?');
    if (r === true) {
      this.messageService.add({type: 'warn', message: 'Attempting to flag product...'});
      const userId = this.getUserId(); // use this to save who flagged the helmet

      // Send a slack message and don't save to DB;
      if (form.value.flagValue === 'Other') {
        return this.postslackMesage(form.value.otherValue, userId);
      }
        // call the backend API
      this.productService.flagProductwithObservable(this.formatFlagBody(form.value, userId))
      .subscribe(response => {
        if (response.success) {
          this.messageService.add({type: 'success', message: `Helmet flagged as ${JSON.stringify(form.value.flagValue)}`});
          this.router.navigateByUrl(`/cs/order/${this.route.snapshot.paramMap.get('id')}`);
        } else {
          this.messageService.add({type: 'error', message: 'Could not flag helmet'});
          this.messageService.add({type: 'error', message: response.error});
          this.toggleFlagForm();
        }
      });
    }

  }
  postslackMesage(value, user) {
    this.slackService.postSerialSlack(`User: ${user} has tried to post a flag for product (${this.product}). Requested flag value: "${value}"`);
    this.toggleFlagForm();
    this.messageService.add({
      type: 'success',
      message: `Because you selected "Other" a message was sent to XTech to approve the flag value you typed in. Requested flag value: "${value}"`
    });
  }

  formatFlagBody(formValues, userId) {
    return {
      'object': {
        'flagType': formValues.flagValue,
        'productExpId': this.product,
        'deActivate': true,
        'userId': userId
      },
      'options': {
        'include': []
      }
    }
  }

  getUserId() {
    return JSON.parse(localStorage.getItem('token'))['cognito:username'];
  }
}
