import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

// material imports
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';


import { AppComponent } from './app.component';
import { LastOrdersComponent } from '../app/common/last-orders/last-orders.component';
import { AutofocusDirective } from './autofocus.directive';
import { AppRoutingModule } from './/app-routing.module';
import { JsBarcodeModule } from 'ngx-jsbarcode';

// auth imports
import { ExpAuthModule, AuthEvent, ExpAuthConfig, ExpAuthInterceptor, LoginComponent,ExpAuthService } from './exp-auth/exp-auth.module';
import { environment } from '../environments/environment';
import { LoginWrapperComponent } from './login-wrapper/login-wrapper.component';
import { ExpAuthGuard } from './exp-auth/exp-auth-guard';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BarcodeGeneratorComponent } from './common/barcode-generator/barcode-generator.component';
import { ConfigDetailsComponent } from './common/config-details/config-details.component';
import { ScannedHelmetsComponent } from './common/scanned-helmets/scanned-helmets.component';
import { MessagesComponent } from './messages/messages.component';
import { ReconOrderComponent } from './recon/recon-order/recon-order.component';
import { SerialDetailsComponent } from './serial-details/serial-details.component';
import { HeaderComponent } from './navigation/header/header.component';
import { FormatUsernamePipe } from './pipes/format-username.pipe';
import { TranslatePipe } from './pipes/translate.pipe';
import { DecoderPipe } from './pipes/decoder.pipe';
import { SearchComponent } from './cs/search/search.component';
import { QualityApprovalComponent } from './serial-details/quality-approval/quality-approval.component';
import { AssemblyViewComponent } from '../app/assembly/assembly-view/assembly-view.component';
import { CustomerServiceViewComponent } from './cs/customer-service-view/customer-service-view.component';
import { CsHelmetDetailsComponent } from './cs/cs-helmet-details/cs-helmet-details.component';
import { CsOrderDetailsComponent } from './cs/cs-order-details/cs-order-details.component';
import { HomepageComponent, DialogOverviewComponent } from './homepage/homepage.component';
import { FindComponent } from './common/find/find.component';
import { LoadingComponent } from './loading/loading.component';
import { UIService } from './services/ui.service';
import { ReconAssemblyViewComponent } from './recon/recon-assembly-view/recon-assembly-view.component';
import { ReconBarcodesComponent } from './recon/recon-barcodes/recon-barcodes.component';
import { SerialReplacementComponent } from './recon/serial-replacement/serial-replacement.component';
import { SerialReprintComponent } from './recon/serial-reprint/serial-reprint.component';
import { DateFormats } from '../assets/dateFormats';

import { PbAssemblyViewComponent } from './pb/pb-assembly-view/pb-assembly-view.component';
import { ASMMainInputComponent } from './common/asm-main-input/asm-main-input.component';
import { ASMProductAssemblyComponent } from './common/asm-product-assembly/asm-product-assembly.component';
import { ASMProductInputComponent } from './common/asm-product-input/asm-product-input.component';
import { PickPackAssemblyComponent } from './pb/pick-pack-assembly/pick-pack-assembly.component';
import { AssemblyComponent } from './common/assembly/assembly.component';
import { PickPackProductsComponent } from './pb/pick-pack-products/pick-pack-products.component';
import { PickPackInputComponent } from './pb/pick-pack-input/pick-pack-input.component';
import { SerialAddNocsaeComponent } from './recon/serial-add-nocsae/serial-add-nocsae.component';
import { ReversePipe } from './pipes/reverse.pipe';
import { CsOrderDetailsWorkOrderComponent } from './cs/cs-order-details-work-order/cs-order-details-work-order.component';
import { QcWidgetComponent } from './serial-details/qc-widget/qc-widget.component';
import { CsHelmetHistoryComponent } from './cs/cs-helmet-history/cs-helmet-history.component';
import { FlaggerComponent } from './cs/flagger/flagger.component';
import { QcListComponent } from './serial-details/qc-list/qc-list.component';


const authConfig: ExpAuthConfig = {
    clientId: environment.clientId,
    clientAuthoriztion: environment.clientAuthoriztion,
    redirectUrl: environment.redirectUrl,
    ssoDomain: environment.ssoDomain
};


@NgModule({
  declarations: [
    AppComponent,
    LastOrdersComponent,
    AutofocusDirective,
    LoginWrapperComponent,
    BarcodeGeneratorComponent,
    ConfigDetailsComponent,
    ScannedHelmetsComponent,
    MessagesComponent,
    ReconOrderComponent,
    SerialDetailsComponent,
    HeaderComponent,
    FormatUsernamePipe,
    TranslatePipe,
    DecoderPipe,
    SearchComponent,
    QualityApprovalComponent,
    AssemblyViewComponent,
    CustomerServiceViewComponent,
    CsHelmetDetailsComponent,
    CsOrderDetailsComponent,
    HomepageComponent,
    DialogOverviewComponent,
    FindComponent,
    LoadingComponent,
    ReconAssemblyViewComponent,
    ReconBarcodesComponent,
    SerialReplacementComponent,
    SerialReprintComponent,
    PbAssemblyViewComponent,
    ASMMainInputComponent,
    ASMProductAssemblyComponent,
    ASMProductInputComponent,
    PickPackAssemblyComponent,
    AssemblyComponent,
    PickPackProductsComponent,
    PickPackInputComponent,
    SerialAddNocsaeComponent,
    ReversePipe,
    CsOrderDetailsWorkOrderComponent,
    QcWidgetComponent,
    CsHelmetHistoryComponent,
    FlaggerComponent,
    QcListComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    JsBarcodeModule,
    FormsModule,
    ExpAuthModule.forRoot(authConfig)
  ],
  entryComponents: [
    DialogOverviewComponent,
],
  exports: [
    ExpAuthModule,
    DialogOverviewComponent
  ],
  providers: [
    ExpAuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ExpAuthInterceptor,
      multi: true
    },
    {provide: MAT_DATE_FORMATS, useValue: DateFormats.MY_FORMATS},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    UIService],
  bootstrap: [AppComponent]
})


export class AppModule {
  constructor(private authService: ExpAuthService) {
    // setInterval(()=>{
    //         if(this.authService.isAuthenticated())
    //             this.authService.refreshAuthorization();
    //     },600000)
  }
 }
