import { Injectable, EventEmitter } from '@angular/core';
import { Order } from '../order';
import { Observable, of, forkJoin, interval, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap, flatMap, retryWhen, delay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { MessageService } from './message.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class OrderService {
  private GETLastOrdersUrl = 'crud/productConfig/list/lastest/25?';
  private POSTOrderUrl = 'crud/productConfig/upsert?';
  private GETOrderUrl = 'crud/productConfig/read/';
  private GETOrderByOrderUrl = 'crud/workOrder/read/';
  private getLatestOrdersUrl = environment.expSerialBaseUrl + this.GETLastOrdersUrl;
  private postProductConfigUrl = environment.expSerialBaseUrl + this.POSTOrderUrl;
  // used by order details
  orderChange: EventEmitter<Order> = new EventEmitter();
  // newOrderChange: EventEmitter<Order> = new EventEmitter();
  // orderListChange: EventEmitter<Order[]> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private messageService: MessageService
  ) { }

    /** GET orders from the server */
  getLastOrders(type= 'Assembly'): Observable<any> {
    // console.log("Type for filter is: " +type);
    return this.http.post<any>(this.getLatestOrdersUrl, {
      'options': {
        'filters': [
          {
            'type': type
          }
        ]
      }
    }, httpOptions).pipe(
      retryWhen(errors =>
        errors.pipe(
          delay(1000),
          tap(errorStatus => {
            // if (!errorStatus.startsWith('5')) {
            //   throw errorStatus;
            // }
            console.log('Retrying...');
          })
        )
      ),
      catchError(this.handleError<any>('getOrders'))
    );
  }

  /** GET order by id. Will 404 if id not found */
  // getOrder(id: string): Observable<any> {
  //   const url = environment.expSerialBaseUrl + this.GETOrderUrl + `${id}?` + environment.expSerailToken;
  //   return this.http.post<Order>(url, {
  //     'options': {
  //       'include': ['Product']
  //     }
  //   }).pipe(
  //     catchError(this.handleError<Order>(`getOrder id=${id}`))
  //   );
  // }
  getOrder(id: string): Observable<any> {
    const url = environment.expSerialBaseUrl + this.GETOrderUrl + `${id}?` + environment.expSerailToken;
    // return this.http.post<Order>(url, {
    //   'options': {
    //     'include': ['Product']
    //   }
    // }).pipe(
    //   catchError(this.handleError<Order>(`getOrder id=${id}`))
    // );
    return forkJoin(
      this.http.post(url, {
      'options': {
        'include': ['Product']
      }
      }), this.http.post(url, {
        'options': {
          'include': ['Operation->QC']
        }
      }), this.http.post(url, {
        'options': {
          'include': ['WorkOrder']
        }
      })
    ).pipe(
      retryWhen(errors =>
        errors.pipe(
          delay(1000),
          tap(errorStatus => {
            // if (!errorStatus.startsWith('5')) {
            //   throw errorStatus;
            // }
            console.log('Retrying...');
          })
        )
      ),
        catchError(this.handleError<Order>(`getOrder id=${id}`))
      );
  }


  getOrderforCS(id: string): Observable<any> {
    const url = environment.expSerialBaseUrl + this.GETOrderByOrderUrl + `${id}?` + environment.expSerailToken;

    return forkJoin(
      this.http.post(url, {
      'options': {
        'include': ['Product']
      }
      }), this.http.post(url, {
        'options': {
          'include': ['Operation->QC']
        }
      }), this.http.post(url, {
        'options': {
          'include': ['ProductConfig']
        }
      })
    ).pipe(
      retryWhen(errors =>
        errors.pipe(
          delay(1000),
          tap(errorStatus => {
            // if (!errorStatus.startsWith('5')) {
            //   throw errorStatus;
            // }
            console.log('Retrying...');
          })
        )
      ),
      catchError(this.handleError<Order>(`getOrderforCS id=${id}`))
    );
  }

  // CS order view only uses this;
  // getOrderByOrder(id: string): Observable<any> {
  //   const url = environment.expSerialBaseUrl + this.GETOrderByOrderUrl + `${id}?` + environment.expSerailToken;
  //   return this.http.post(url, {
  //     'options': {
  //       'include': ['Product', 'ProductConfig']
  //     }
  //   }).pipe(
  //     catchError(this.handleError<Order>(`getOrderbyOrder id=${id}`))
  //   );
  // }
  getOrderByOrderForQC(id: string): Observable<any> {
    const url = environment.expSerialBaseUrl + this.GETOrderByOrderUrl + `${id}?` + environment.expSerailToken;
    return this.http.post(url, {
      'options': {
        'include': ['Operation->QC']
      }
    }).pipe(

      catchError(this.handleError<Order>(`getOrderbyOrder id=${id}`))
    );
  }
  addOrderWithObservable(order): Observable<any> {
    return this.http.post<any>(this.postProductConfigUrl, order, httpOptions).pipe(
      catchError(this.handleError<any>('addOrderwith Observable'))
    );
  }


  // addOrderWithObservable(order: Order): void {
  //     // console.log('Adding Order...');
  //     this.http.post<any>(this.orderPostUrl, order, httpOptions).subscribe(response => {
  //       if (response.success) {
  //         this.newOrder(response.body);
  //         console.log('response: ' + JSON.stringify(response));
  //       }
  //       // return response;
  //     } );
  // }
  selectOrdernoQC(id: string) {
    this.orderChange.emit(null);
    this.getOrder(id).subscribe(order => {
      if (order.success) {
        // console.log('Order -->:' + JSON.stringify(order));
        this.orderChange.emit(order.body);
      } else {
        console.log('Order -->:' + JSON.stringify(order));
        this.messageService.add({type: 'error', message: 'That order does not exist'});
        this.messageService.add({type: 'error', message: 'This is a backend issue, contact XTech to solve'});
      }
    });
  }

  selectOrder(id: string) {
    this.orderChange.emit(null);

    this.getOrder(id).subscribe(([res1, res2, res3]) => {
      const response = res1;
      response.body['operations'] = res2.body.operations;
      response.body['workOrder'] = res3.body.workOrders[0];
      const qc = res2.body.operations.filter(op => op.type === 'QC');
      let hashMap = {};
      for (let item of qc) {
        if (item.QCs && item.QCs.length) {
          if (item.QCs[0].subStatus) {
            hashMap[item.productExpId] = item.QCs[0].status + ' ' + item.QCs[0].subStatus;
          } else {
            hashMap[item.productExpId] = item.QCs[0].status;
          }
        }
      }
      let products = response.body.products;
      for (let i = 0; i < response.body.products.length; i++) {
        if (Object.keys(hashMap).includes(products[i].expId)) {
          response.body.products[i]['QC'] = hashMap[products[i].expId];
        }
      }
      if (response.success) {
        this.orderChange.emit(response.body);
        // console.log('Order -->:' + JSON.stringify(response));
      } else {
        console.log('Order -->:' + JSON.stringify(response));
        this.messageService.add({type: 'error', message: 'That order does not exist'});
      }
    });
  }

  sortArray(products, sortKey) {
    return products.sort(function(a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      // const a1 = +new Date(a.sortKey);
      // const b1 = +new Date(b.sortKey);
      // return b1 - a1;
      return +new Date(b.date) - +new Date(a.date);
    });
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.messageService.add({type: 'error', message: error.message});
      return of(result as T);
    };
  }
}
