import { Product } from './product';
export class Order {
  expId: string;
  assemblyDate: string;
	orderNumber: string;
	configKey: string;
	productModel: string;
	productColor: string;
	productQuantity: string;
	zipCode?: string;
  products?: Product[];
  operations?: Operation[];
}

export class Operation {
  expId: string;
  type: string;
  createdAt: string;
  productExpId: string;
  workOrderExpId: string;
  QCs?: Object[];
}
