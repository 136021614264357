export class ModelConstants {
  public static Models = {
    980: 'ShadowXR Varsity XL',
    970: 'ShadowXR Varsity L',
    960: 'ShadowXR Varsity M',
    940: 'ShadowXR Youth L',
    930: 'ShadowXR Youth M',
    920: 'ShadowXR Youth S',
    880: 'Shadow XL',
    870: 'Shadow L',
    860: 'Shadow M',
    780: 'Epic+ Varsity XL',
    770: 'Epic+ Varsity L',
    760: 'Epic+ Varsity M',
    680: 'Epic+ Youth XL',
    670: 'Epic+ Youth L',
    660: 'Epic+ Youth M',
    650: 'Epic+ Youth S',
    580: 'X2E+ Varsity XL',
    570: 'X2E+ Varsity L',
    560: 'X2E+ Varsity M',
    550: 'X2E+ Varsity S',
    470: 'X2E+ Youth L',
    460: 'X2E+ Youth M',
    450: 'X2E+ Youth S',
  };
}
