import { Component, OnInit, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'src/app/services/product.service';
import { OrderService } from 'src/app/services/order.service';
import {style, state, animate, transition, trigger} from '@angular/animations';
import { ConfigDecoderService } from 'src/app/services/config-decoder.service';
import { EventEmitter } from 'events';
import { Subject } from 'rxjs';
import { MessageService } from 'src/app/services/message.service';


@Component({
  selector: 'app-customer-service-view',
  templateUrl: './customer-service-view.component.html',
  styleUrls: ['./customer-service-view.component.css'],
  // animations: [
  //   trigger('fadeInOut', [
  //     transition(':enter', [
  //       style({
  //         width: '0px'
  //       }),
  //       animate('5.5s cubic-bezier(0.35, 0, 0.25, 1)', style({width: '100%'}))
  //     ]),
  //     transition(':leave', [   // :leave is alias to '* => void'
  //       animate(10000, style({opacity:0}))
  //     ])
  //   ])
  // ]
})
export class CustomerServiceViewComponent implements OnInit {
  eventsSubject: Subject<void> = new Subject<void>();
  helmet;
  searched;
  // order;
  @Output() orderEvent = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    private orderService: OrderService,
    private messageService: MessageService,
    private decoder: ConfigDecoderService
    ) { }

  ngOnInit() {
    this.chooseGetMethod();
  }


  chooseGetMethod(inputedId = '') {
    let id = '';
    // check to see if navigatating via link or search
    if (inputedId === '') {
      id = this.route.snapshot.paramMap.get('id');
    } else {
      id = inputedId;
    }
    if (!id) { return; }
    switch (id.slice(0, 1).toUpperCase()) {
      // case 'N':
      // case 'S':
      //   // this.order = null;
      //   this.router.navigateByUrl(`/cs/helmet/${id}`);
      //   // Search via get helmet
      //   this.getHelmet(id);
      //   break;
      case 'O':
      case 'R':
      case 'A':
        this.searched = 'order';
        this.helmet = null;
        // Search via get Order
        this.getOrder(id);
        this.router.navigateByUrl(`/cs/order/${id}`);
        break;
      default:
        // this.searched = null;
        this.router.navigateByUrl(`/cs/helmet/${id}`);
        // Search via get helmet
        this.getHelmet(id);
        // console.log('You can only search for Serial numbers or orders');
        // this.messageService.add({type: 'warn', message: 'You can only search for Serial numbers or orders'});
    }
  }
  // receive new search term from search component
  recieveMessage($event) {
    // this.router.navigateByUrl(`/cs/${$event}`);
    this.chooseGetMethod($event);
  }

  getHelmet(id) {
    this.searched = 'helmet';
    this.helmet = null;
    this.productService.getProductDetails(id).subscribe(response => {
      if (response.success) {
        // this.processResponse(response);
        return this.helmet = response.body;

      } else {
        this.helmet = null;
        this.searched = null;
        // post to message service
        this.messageService.add({type: 'warn', message: 'That helmet is not saved in our Database'})
      }
    });
  }

  getOrder(id) {
    //call get order with QC info
    this.orderService.getOrderforCS(id).subscribe(([res1, res2, res3]) => {
      const response = res1;
      if (response.success) {
      response.body['productConfigs'] = res3.body.productConfigs;
      const qc = res2.body.operations.filter(op => op.type === 'QC');
      let hashMap = {};
      for (let item of qc) {
        if (item.QCs && item.QCs.length) {
          if (item.QCs[0].subStatus) {
            hashMap[item.productExpId] = item.QCs[0].status + ' ' + item.QCs[0].subStatus;
          } else {
            hashMap[item.productExpId] = item.QCs[0].status;
          }
        }
      }
      let products = response.body.products;
      for (let i = 0; i < response.body.products.length; i++) {
        if (Object.keys(hashMap).includes(products[i].expId)) {
          response.body.products[i]['QC'] = hashMap[products[i].expId];
        }
      }
        return this.eventsSubject.next(response.body);
      } else {
        this.searched = null;
        this.messageService.add({type: 'warn', message: response.error});
        return this.eventsSubject.next(null);
      }
    });
  }

  processResponse(response) {
    const config = response.body.config;
    response.body.productColor = this.decoder.getColor(config.color);
    response.body.productModel = this.decoder.getModel(config.productModel);
    response.body.productModel = this.decoder.getSize(config.productModel);
    return response;
  }
}
