import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { ProductService } from '../services/product.service';
import { Product } from '../product';
import { Order } from '../order';
import { MessageService } from '../services/message.service';
import { QcService } from '../services/qc.service';
import { retryWhen, delay, tap } from 'rxjs/operators';

@Component({
  selector: 'app-serial-details',
  templateUrl: './serial-details.component.html',
  styleUrls: ['./serial-details.component.css']
})
export class SerialDetailsComponent implements OnInit {
  product: Product;
  orders: Order[];
  qcOrder: Order;
  received = false;
  exists = false;
  STATUS = 'Status';
  DATE = 'Assembly Date';
  FIND = 'FIND';

  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private messageService: MessageService,
    private location: Location,
    private qcService: QcService
  ) { }

  ngOnInit(): void {
    this.getSerial();
  }

  getSerial(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.productService.getProductDetails(id)
    .pipe(
      retryWhen(errors =>
        errors.pipe(
          delay(1000),
          tap(errorStatus => {
            // if (!errorStatus.startsWith('5')) {
            //   throw errorStatus;
            // }
            console.log('Retrying...');
          })
        )
    )).subscribe(response => {
      const body = response.body;
      if (response.success) {

        // console.log(`Response --> ${JSON.stringify(response)}`)
        // if (!body.qcStatus.length) { body.qcStatus = [{'status': 'Not Checked', 'subStatus': null}]; }
        this.exists = true;
        let tempStatus = {'status': 'Awaiting QC', 'subStatus': null, createdAt: null};
        // if (body.QCs.length) {
        //   tempStatus =  body.QCs[0]
        // }
        let currentQCOperation = response.body.operations.filter((operation) => operation.type === 'QC')[0];

        if (currentQCOperation && currentQCOperation.QCs.length > 0) {
          tempStatus = currentQCOperation.QCs[0];
          this.qcService.getQCNumbers(currentQCOperation.workOrderExpId);
        } else {
          this.qcService.setWorkOrder(currentQCOperation.workOrderExpId);
        }
        this.product = {
          expId: body.expId,
          serialIndex: body.serialIndex,
          orderId: body.orderId,
          qcStatus: tempStatus,
          // qcStatus: this.handleQCStatus(body),
          assemblyDate: body.assemblyDate,
          destroyTime: body.destroyTime,
          newEntry: true
        };
        // const tempOrder = body['productConfigs'];
        this.orders = body['productConfigs'];

        this.qcOrder = response.body.productConfigs.filter(config => config.orderNumber == currentQCOperation.workOrderExpId)[0];
        // this.order = {
        //   expId: tempOrder.expId,
        //   assemblyDate: tempOrder.assemblyDate,
        //   orderNumber: tempOrder.orderNumber,
        //   configKey: tempOrder.configKey,
        //   productModel: tempOrder.productModel,
        //   productColor: tempOrder.productColor,
        //   productQuantity: tempOrder.productQuantity,
        //   products: []
        // };
      } else {
        this.messageService.add({type: 'warn', message: response.error});
      }
      this.received = true;
    });
  }
  openQCWidget(id) {
    // id = id.slice('-')[0];
    this.qcService.getQCNumbers(id);
  }
  compare(a, b) {
    if (a.createdAt > b.createdAt) {return -1; }
    if (a.createdAt < b.createdAt) {return 1; }
    return 0;
  }
  handleQCStatus(product) {

    //Sort with filters expression in call
    // if ('qcStatus' in product) {
    //   return product.qcStatus.sort(this.compare)[0];
    // } else {
    //   return [];
    // }
  }

  receiveQCStatus($event) {
    this.product.qcStatus = $event;
  }
}
