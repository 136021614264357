import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cs-order-details-work-order',
  templateUrl: './cs-order-details-work-order.component.html',
  styleUrls: ['./cs-order-details-work-order.component.css']
})
export class CsOrderDetailsWorkOrderComponent implements OnInit {
  @Input() order;
  constructor() { }

  ngOnInit() {
  }

}
